import { BehaviorSubject } from 'rxjs';

export class routes {
  public static layoutDirection: BehaviorSubject<string> =
    new BehaviorSubject<string>(localStorage.getItem('rtl') || '');

  private static Url = '';

  static rtl = this.layoutDirection.subscribe((res: string) => {
    this.Url = res;
  });


  // FOR HRIS ONLY


  public static get baseUrl(): string {
    return this.Url;
  }
  public static get login(): string {
    return this.baseUrl + '/login';
  }
  public static get loginpro(): string {
    return this.baseUrl + '/login';
  }
  public static get forgot_password(): string {
    return this.baseUrl + '/forgot-password';
  }
  public static get forgotpassword(): string {
    return this.baseUrl + '/forgot-password';
  }




  public static get dashboard(): string {
    return this.baseUrl + '/dashboard';
  }
  public static get admin(): string {
    return this.baseUrl + '/dashboard/admin';
  }
  public static get employeeDashboard(): string {
    return this.baseUrl + '/dashboard/employee';
  }
  public static get adminDashboard(): string {
    return this.baseUrl + '/dashboard/admin';
  }






  public static get employees(): string {
    return this.baseUrl + '/employees';
  }
  public static get manageTraining(): string {
    return this.baseUrl + '/employees/manage-training';
  }
  public static get employee_page(): string {
    return this.baseUrl + '/employees/employee-page';
  }
  public static get holidays(): string {
    return this.baseUrl + '/employees/holidays';
  }
  public static get leaveadmin(): string {
    return this.baseUrl + '/employees/leave-admin';
  }
  public static get leaveemployee(): string {
    return this.baseUrl + '/employees/leave-employee';
  }
  public static get leavesettings(): string {
    return this.baseUrl + '/employees/leave-settings';
  }
  public static get attendanceadmin(): string {
    return this.baseUrl + '/employees/attendance-admin';
  }
  public static get attendanceemployee(): string {
    return this.baseUrl + '/employees/attendance-employee';
  }
  public static get departments(): string {
    return this.baseUrl + '/employees/departments';
  }
  public static get designations(): string {
    return this.baseUrl + '/employees/designations';
  }
  public static get timesheet(): string {
    return this.baseUrl + '/employees/timesheet';
  }
  public static get shiftschedule(): string {
    return this.baseUrl + '/employees/shift-schedule';
  }
  public static get overtime(): string {
    return this.baseUrl + '/employees/overtime';
  }
  public static get downloadable(): string {
    return this.baseUrl + '/employees/downloadable'
  }
  public static get audit_logs(): string {
    return this.baseUrl + '/employees/audit-logs'
  }
  public static get orgChart(): string {
    return this.baseUrl + '/employees/organization-chart'
  }
  public static get training_list(): string {
    return this.baseUrl + '/employees/training-list'
  }
  public static get all_applicant(): string {
    return this.baseUrl + '/employees/all-applicant'
  }
  public static get applicant_profile(): string {
    return this.baseUrl + '/employees/applicant-profile'
  }
  public static get employeeProfile(): string {
    return this.baseUrl + '/employees/employee-profile';
  }
  public static get manageVacancySource(): string {
    return this.baseUrl + '/employees/manage-vacancy-source';
  }
  public static get manageInfractionTemplates(): string {
    return this.baseUrl + '/employees/manage-infraction-templates';
  }
  public static get manageViolationCodes(): string {
    return this.baseUrl + '/employees/manage-violation-codes';
  }


  public static get assets(): string {
    return this.baseUrl + '/assets/assets-main';
  }
  public static get assetsDetails(): string {
    return this.baseUrl + '/assets/assets-details';
  }
  public static get assetSupplier(): string {
    return this.baseUrl + '/assets/assets-supplier';
  }
  public static get manageItemCodes(): string {
    return this.baseUrl + '/assets/manage-item-codes';
  }
  public static get manageItemCategories(): string {
    return this.baseUrl + '/assets/manage-item-categories';
  }



  public static get viewPr(): string {
    return this.baseUrl + '/assets/pr/view';
  }
  public static get prListBy(): string {
    return this.baseUrl + '/assets/pr/by';
  }

  public static get managePrList(): string {
    return this.baseUrl + '/assets/pr/manage/pr-list';
  }
  public static get managePoList(): string {
    return this.baseUrl + '/assets/pr/manage/po-list';
  }
  public static get managePoForReceive(): string {
    return this.baseUrl + '/assets/pr/manage/po-for-receiving-list';
  }



  public static get payroll(): string {
    return this.baseUrl + '/payroll';
  }
  public static get sss(): string {
    return this.baseUrl + '/payroll/sss'
  }
  public static get pagibig(): string {
    return this.baseUrl + '/payroll/pagibig'
  }
  public static get philhealth(): string {
    return this.baseUrl + '/payroll/philhealth'
  }
  public static get withholdtax(): string {
    return this.baseUrl + '/payroll/withhold-tax'
  }
  public static get manageEmployeesalary(): string {
    return this.baseUrl + '/payroll/manage-employee-salary';
  }
  public static get payrollitems(): string {
    return this.baseUrl + '/payroll/payroll-items';
  }
  public static get employeeSalaryView(): string {
    return this.baseUrl + '/payroll/employee-salary';
  }
  public static get manageDeduction(): string {
    return this.baseUrl + '/payroll/manage-deduction';
  }
  public static get contributionReport(): string {
    return this.baseUrl + '/payroll/contribution-report';
  }
  public static get payrollForApprovals(): string {
    return this.baseUrl + '/payroll/for-approvals';
  }



  public static get paymentsreport(): string {
    return this.baseUrl + '/reports/payments-report';
  }




  public static get detachments(): string {
    return this.baseUrl + '/clients/detachments';
  }
  public static get clientPage(): string {
    return this.baseUrl + '/clients/client-page';
  }
  public static get clientProfile(): string {
    return this.baseUrl + '/clients/client-profile';
  }






  public static get error404(): string {
    return this.baseUrl + '/404';
  }
  public static get error500(): string {
    return this.baseUrl + '/500';
  }
  public static get error(): string {
    return this.baseUrl + '/404';
  }
  public static get errors(): string {
    return this.baseUrl + '/500';
  }






  // RECONSIDER
  public static get registers(): string {
    return '/login';
    // return this.baseUrl + '/register';
  }
  public static get otp(): string {
    return '/login';
    // return this.baseUrl + '/otp';
  }





  // FROM TEMPLATE
  public static get baseUi(): string {
    return '/login';
    // return this.baseUrl + '/base-ui';
  }
  public static get components(): string {
    return '/login';
    // return this.baseUrl + '/components';
  }
  public static get userAssetsDetails(): string {
    return '/login';
    // return this.baseUrl + '/assets/user-assets-details';
  }
  public static get elements(): string {
    return '/login';
    // return this.baseUrl + '/elements';
  }
  public static get ribbon(): string {
    return '/login';
    // return this.elements + '/ribbon';
  }
  public static get clipboards(): string {
    return '/login';
    // return this.elements + '/clipboards';
  }
  public static get dragDrop(): string {
    return '/login';
    // return this.elements + '/drag-drop';
  }
  public static get rating(): string {
    return '/login';
    // return this.elements + '/rating';
  }
  public static get textEditor(): string {
    return '/login';
    // return this.elements + '/text-editor';
  }
  public static get counter(): string {
    return '/login';
    // return this.elements + '/counter';
  }
  public static get scrollbar(): string {
    return '/login';
    // return this.elements + '/scrollbar';
  }
  public static get notification(): string {
    return '/login';
    // return this.elements + '/notification';
  }
  public static get stickyNote(): string {
    return '/login';
    // return this.elements + '/sticky-note';
  }
  public static get timeline(): string {
    return '/login';
    // return this.elements + '/timeline';
  }
  public static get horizontal(): string {
    return '/login';
    // return this.elements + '/horizontal-timeline';
  }
  public static get formWizard(): string {
    return '/login';
    // return this.elements + '/form-wizard';
  }
  public static get charts(): string {
    return '/login';
    // return this.baseUrl + '/charts';
  }
  public static get apexChart(): string {
    return '/login';
    // return this.charts + '/apex-charts';
  }
  public static get ngTwoCharts(): string {
    return '/login';
    // return this.charts + '/ng2-charts';
  }
  public static get icon(): string {
    return '/login';
    // return this.baseUrl + '/icon';
  }
  public static get fontawesome(): string {
    return '/login';
    // return this.icon + '/fontawesome';
  }
  public static get feather(): string {
    return '/login';
    // return this.icon + '/feather';
  }
  public static get ionic(): string {
    return '/login';
    // return this.icon + '/ionic';
  }
  public static get material(): string {
    return '/login';
    // return this.icon + '/material';
  }
  public static get pe7(): string {
    return '/login';
    // return this.icon + '/pe7';
  }
  public static get themify(): string {
    return '/login';
    // return this.icon + '/themify';
  }
  public static get typicon(): string {
    return '/login';
    // return this.icon + '/typicon';
  }
  public static get weather(): string {
    return '/login';
    // return this.icon + '/weather';
  }
  public static get simpleLine(): string {
    return '/login';
    // return this.icon + '/simple-line';
  }
  public static get flag(): string {
    return '/login';
    // return this.icon + '/flag';
  }
  public static get alert(): string {
    return '/login';
    // return this.baseUi + '/alert';
  }
  public static get accordions(): string {
    return '/login';
    // return this.baseUi + '/accordions';
  }
  public static get avatar(): string {
    return '/login';
    // return this.baseUi + '/avatar';
  }
  public static get badges(): string {
    return '/login';
    // return this.baseUi + '/badges';
  }
  public static get buttons(): string {
    return '/login';
    // return this.baseUi + '/buttons';
  }
  public static get buttonGroup(): string {
    return '/login';
    // return this.baseUi + '/button-group';
  }
  public static get breadcrumb(): string {
    return '/login';
    // return this.baseUi + '/breadcrumb';
  }
  public static get cards(): string {
    return '/login';
    // return this.baseUi + '/cards';
  }
  public static get carousel(): string {
    return '/login';
    // return this.baseUi + '/carousel';
  }
  public static get dropDown(): string {
    return '/login';
    // return this.baseUi + '/drop-down';
  }
  public static get grid(): string {
    return '/login';
    // return this.baseUi + '/grid';
  }
  public static get images(): string {
    return '/login';
    // return this.baseUi + '/images';
  }
  public static get lightBox(): string {
    return '/login';
    // return this.baseUi + '/light-box';
  }
  public static get media(): string {
    return '/login';
    // return this.baseUi + '/media';
  }
  public static get modal(): string {
    return '/login';
    // return this.baseUi + '/modal';
  }
  public static get offcanvas(): string {
    return '/login';
    // return this.baseUi + '/offcanvas';
  }
  public static get pagination(): string {
    return '/login';
    // return this.baseUi + '/pagination';
  }
  public static get placeholder(): string {
    return '/login';
    // return this.baseUi + '/placeholder';
  }
  public static get popover(): string {
    return '/login';
    // return this.baseUi + '/popover';
  }
  public static get progressBars(): string {
    return '/login';
    // return this.baseUi + '/progress-bars';
  }
  public static get rangeSlider(): string {
    return '/login';
    // return this.baseUi + '/range-slider';
  }
  public static get spinner(): string {
    return '/login';
    // return this.baseUi + '/spinner';
  }
  public static get tabs(): string {
    return '/login';
    // return this.baseUi + '/tabs';
  }
  public static get sweetAlert(): string {
    return '/login';
    // return this.baseUi + '/sweet-alerts';
  }
  public static get toasts(): string {
    return '/login';
    // return this.baseUi + '/toasts';
  }
  public static get tooltip(): string {
    return '/login';
    // return this.baseUi + '/tooltip';
  }
  public static get typography(): string {
    return '/login';
    // return this.baseUi + '/typography';
  }
  public static get video(): string {
    return '/login';
    // return this.baseUi + '/video';
  }
}
