import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FapiService {
  private readonly providerPath: string = environment.hostURL;
  private readonly providerPath2nd: string = environment.hostURL2;

  constructor(
    private http: HttpClient
  ) { }

  public blob = {

    sendGetRequest: (path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        params: params,
        observe: "response" as "body",
        responseType: "blob" as const,
      };
      return this.http.get(`${this.providerPath}${path}`, requestOptions);
    }

  };

  sendGetRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      params: params,
      observe: "response" as 'body',
    };
    return this.http.get<T>(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPostRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.post<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPatchRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.patch<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPutRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.put<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendDeleteRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.delete<T>(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  public raw = {
    sendPutRequest: <T>(path: string, body: any, headers: HttpHeaders, params: HttpParams) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.put<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendGetRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        params: params,
        observe: "response" as 'body',
      };
      return this.http.get<T>(path, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendPostRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.post<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendPatchRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.patch<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendDeleteRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.delete<T>(path, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
  }

  public path2nd = {
    sendPutRequest: <T>(path: string, body: Object, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      return this.raw.sendPutRequest<HttpResponse<T>>(`${this.providerPath2nd}${path}`, body, headers, params);
    },
    sendGetRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      return this.raw.sendGetRequest<HttpResponse<T>>(`${this.providerPath2nd}${path}`, headers, params);
    },
    sendPostRequest: <T>(path: string, body: Object, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      return this.raw.sendPostRequest<HttpResponse<T>>(`${this.providerPath2nd}${path}`, body, headers, params);
    },
    sendPatchRequest: <T>(path: string, body: Object, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      return this.raw.sendPatchRequest<HttpResponse<T>>(`${this.providerPath2nd}${path}`, body, headers, params);
    },
    sendDeleteRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      return this.raw.sendDeleteRequest<HttpResponse<T>>(`${this.providerPath2nd}${path}`, headers, params);
    },
  }


}
