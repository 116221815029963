// Development / Unit Testing Environment
const signInConfig = {
  WEB_PROTOCOL: 'https://',
  DOMAIN_NAME: 'https://dev-hris.agf-security.com ',
  CLIENT_ID: '2bo2f3en8qpvcvect8e1n8fhnj',
  RESPONSE_TYPE: 'token',
  REDIRECT_SIGN_IN: 'https://dev-hris.agf-security.com /login',
  REDIRECT_SIGN_OUT: 'https://dev-hris.agf-security.com /login',
  SCOPE: ['email', 'openid'],
}

export const environment = {
  production: false,
  app_version: '2023.0.0.1 - UAT',
  homepage: '/login',
  hostURL: 'https://axj5nn4c99.execute-api.ap-southeast-1.amazonaws.com/dev',
  hostURL2: 'https://gnyakww7y9.execute-api.ap-southeast-1.amazonaws.com/dev',

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`
};