import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

class Header {
  public static get json() {
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }
}

export class APIHelper {
  public static Header = Header;

  public static subscribe(api: Observable<Object>, next: Function, apiError?: Function) {
    api.subscribe({
      next: (data: any) => {
        next(data as HttpResponse<any>);
      },
      error: (err: any) => {
        apiError ? apiError(err) : console.error({ err });
      }
    });
  }

}

// Fix when ng serve is not working
// node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve