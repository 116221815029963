import { coerceStringArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from 'src/app/shared/api.service';

// Interfaces
import { DesingationAccessInterface, LoginResponse, UserInfo_AllInfoInterface, UserInfo_LoginDataInterface, UserInfo_ProjectsInterface, UserInfo_UserInterface } from 'src/app/shared/interface/global-interface';
import { Helper } from '../../helpers/methods/Helper';
import { LstorageService } from '../lstorage/lstorage.service';
import { routes } from '../../core.index';

interface register {
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
}
interface login {
  email: string;
  password: string
}
interface returndata {
  message: string;
  status: string
}
@Injectable({
  providedIn: 'root',
})
export class WebStorage {
  constructor(
    private router: Router,
    private api: ApiService,
    private storage: LstorageService,
  ) { }

  public User_CreateAccount(uservalue: register): void {
  }

  public Client_Login(body: LoginResponse) {
    Helper.UserLoginData_InitAsClient(body, this._getClientAcess());
    this.Createtoken(body.loginToken);
    this.nagivate_to_correct_path();
  }

  public User_Login(newData: UserInfo_AllInfoInterface, token: string): void {
    this.UpdateUserLoginData(newData);
    this.Createtoken(token);
    this.nagivate_to_correct_path();
  }


  public UpdateUserLoginDataAsClient() {
    const body: LoginResponse = {
      ID: Helper.UserLoginData().ID,
      Role: "Client",
      UserName: Helper.UserLoginData().UserName,
      loginToken: Helper.UserLoginData().Token,
    };
    Helper.UserLoginData_InitAsClient(body, this._getClientAcess());
  }

  public UpdateUserLoginData(newData: UserInfo_AllInfoInterface) {
    const body = {
      ...newData,
      user: [...(newData.user as any).map((v: any) => ({
        ...v,
        ReportsTo: JSON.parse(v.ReportsTo),
        BirthDate: Helper.ToDetailedDate(v.BirthDate),
        JoinDate: Helper.ToDetailedDate(v.JoinDate),
        Gender: (v.Gender == 'F' ? 'Female' : 'Male'),
        Department: JSON.parse(v.Department),
        Designation: JSON.parse(v.Designation),
      }))][0],
    };

    const latestProject = body.projects.find(v => Helper.DateIsBetween2Dates(new Date(), new Date(v.DateAssignFrom), new Date(v.DateAssignTo)));

    Helper.UserLoginData_Init({ ...body, Project: latestProject?.Detachment.Name, Birthday: body.user.BirthDate });
  }

  private nagivate_to_correct_path() {
    if (Helper.can(Helper.accessList['view-as-admin-dashboard'])) this.router.navigate([routes.adminDashboard]);
    if (Helper.can(Helper.accessList['view-as-employee-dashboard']) || Helper.can(Helper.accessList['view-as-client-dashboard'])) this.router.navigate([routes.employeeDashboard]);
    if (Helper.can(Helper.accessList['view-as-applicant-dashboard'])) this.router.navigate([routes.applicant_profile, Helper.UserLoginData().ID]);
  }

  public User_CheckLog(): void {
    const logindata = String(localStorage.getItem('LoginData'));
    if (logindata) this.nagivate_to_correct_path();
    else window.location.href = "/login";
  }

  public User_Logout() {
    this.User_ClearData();
    window.location.href = "/login";
  }

  public User_ClearData() {
    this.storage.remove('Birthday');
    this.storage.remove('DTRManagement');
    this.storage.remove('Department');
    this.storage.remove('FirstName');
    this.storage.remove('LastName');
    this.storage.remove('LoginData');
    this.storage.remove('SL');
    this.storage.remove('VL');
    this.storage.remove('assignment');
    this.storage.remove('baricon');
    this.storage.remove('designation');
    this.storage.remove('loginaccess');
    this.storage.remove('loginid');
    this.storage.remove('logintime');
    this.storage.remove('profpic');
    this.storage.remove('secured');
    this.storage.remove('selected_id');
    this.storage.remove('userName');
    this.storage.remove('attempt-failed');
  }

  private Createtoken(loginToken: string) {
    localStorage.setItem('LoginData', loginToken);
  }



  private _getClientAcess(): DesingationAccessInterface[] {
    return [
      {
        "Actions": [
          true,
          true,
          true
        ]
      },
      {
        "Actions": [
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          true,
          false,
          false,
          false
        ]
      },
      {
        "Actions": [
          true,
          true,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          true
        ]
      }
    ];
  }

}
