import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './feature-module/auth/error404/error404.component';
import { LandingPageComponent } from './feature-module/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: 'agf-security-agency',
    component: LandingPageComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./feature-module/feature-module.module').then(
        (m) => m.FeatureModuleModule
      ),
  },
  {
    path: '**',
    component: Error404Component,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
