import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../helpers/methods/Helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public router: Router,
    private _aRoute: ActivatedRoute,
  ) { }

  canActivate(): boolean {
    if (!localStorage.getItem('LoginData')) {
      if (!window.location.href.includes('/login')) alert('User session expired. Please login again');
      this.router.navigate(['/login']);
      localStorage.clear();
      return false;
    }
    return true;
  }
}
