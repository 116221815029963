import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FapiService {
  private providerPath: string = environment.hostURL;
  constructor(
    private http: HttpClient
  ) { }

  public blob = {

    sendGetRequest: (path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        params: params,
        observe: "response" as "body",
        responseType: "blob" as const,
      };
      return this.http.get(`${this.providerPath}${path}`, requestOptions);
    }

  };

  sendGetRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      params: params,
      observe: "response" as 'body',
    };
    return this.http.get<T>(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPostRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.post<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPatchRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.patch<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPutRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.put<T>(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendDeleteRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.delete<T>(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  public raw = {
    sendPutRequest: <T>(path: string, body: any, headers: HttpHeaders, params: HttpParams) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.put<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendGetRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        params: params,
        observe: "response" as 'body',
      };
      return this.http.get<T>(path, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendPostRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.post<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendPatchRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.patch<T>(path, body, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
    sendDeleteRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.delete<T>(path, requestOptions).pipe(
        catchError(err => {
          alert(`Server error occurred. Try again later`);
          throw err;
        })
      );
    },
  }


}
