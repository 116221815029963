import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LstorageService {
  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public number = {
    set: (key: string, value: number) => {
      localStorage.setItem(key, String(value));
    },
    get: (key: string): number | null => {
      return Number(localStorage.getItem(key));
    },
  }

  public string = {
    set: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
    get: (key: string) => {
      return localStorage.getItem(key);
    },
  }

  public object = {
    set: (key: string, object: Object) => {
      localStorage.setItem(key, JSON.stringify(object));
    },
    get: (key: string): Object => {
      return JSON.parse(localStorage.getItem(key) ?? '{}') as Object
    }
  }

  public array = {
    set: (key: string, array: any[]) => {
      localStorage.setItem(key, JSON.stringify(array));
    },
    get: (key: string): any[] => {
      return JSON.parse(localStorage.getItem(key) ?? '[]') as any[]
    }
  }

}
