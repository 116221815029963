import { Injectable } from '@angular/core';
import { HHTProviderService } from './services/http/http-provider.service';
import { HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Helper } from '../core/helpers/methods/Helper';
import { APIHelper } from '../core/helpers/methods/api-helper';
import { FapiService } from '../core/services/fapi/fapi.service';
import { APIResponse, AssetInterface, CashbondReportInterface, DeductionReportInterface, DetachmentBasicInterface, EmployeeYearSalarySummaryInterface, GovernmentContributionDeductionBasis, LoanReportInterface, RetirementPayReportInterface, ThirteenthMonthReportInterface, ViolationCategoryInterface, ViolationCodeInterface, ViolationInterface } from './interface/global-interface';
import { map, Observable, of, tap } from 'rxjs';
import { IEmployee } from 'cm-angular-org-chart/lib/employee';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpProvider: HHTProviderService,
    private fapi: FapiService,
  ) { }

  public validateLogin(userName: string, password: string) {
    return this.httpProvider.sendGetRequest(
      `/verify_login?&username=${userName}&password=${password}`,
      new HttpHeaders,
      new HttpParams
    );
  }

  public employee = {

    form201: (empId: number) => {
      return this.fapi.blob.sendGetRequest(`/api/v1/user/${empId}/201-file`, new HttpHeaders(), new HttpParams()).pipe(
        map((values: any) => values as HttpResponse<Blob>)
      )
    },

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/employee/add',
        { ...body, rID: Helper.UserLoginData().ID },
        APIHelper.Header.json,
        new HttpParams()
      );
    },

    update: (body: any) => {
      return this.httpProvider.sendPostRequest(
        '/employee/update',
        { ...body, rID: Helper.UserLoginData().ID },
        APIHelper.Header.json,
        new HttpParams()
      );
    },

    delete: {
      byId: (id: number) => {
        return this.httpProvider.sendPostRequest(
          `/employee/delete`,
          { ID: id, Status: '1', rID: Helper.UserLoginData().ID },
          APIHelper.Header.json,
          new HttpParams()
        )
      },

      byTable: (body: Object) => {
        return this.httpProvider.sendPostRequest(
          `/employee/delete`,
          { ...body, rID: Helper.UserLoginData().ID },
          APIHelper.Header.json,
          new HttpParams()
        )
      }
    },

    put: {
      fileDirectory: (file?: File | null, url?: string | null): Observable<Object | null> => {
        if (!file) return of(null);
        if (!url) return of(null);

        return this.httpProvider.sendPutRequest_Raw(
          url,
          file,
          new HttpHeaders({ "content-type": file.type }),
          new HttpParams()
        );
      }
    },

    getAll: () => {
      return this.httpProvider.sendGetRequest('/employee-list', new HttpHeaders(), new HttpParams());
    },

    getByColumns: (columns: Record<string, any>) => {
      return this.httpProvider.sendGetRequest(Helper.ToURLParams('/employee-list', columns), new HttpHeaders(), new HttpParams());
    },

    getDetiled: (id: number) => {
      return this.httpProvider.sendGetRequest(`/employee?ID=${id}`, new HttpHeaders(), new HttpParams());
    },

    img: {

      getUploadURL: (file?: File | null, user_id?: number | null): Observable<null | Object> => {
        if (!file) return of(null);
        if (!user_id) return of(null);

        return this.httpProvider.sendPostRequest(
          '/get/presigned/upload',
          {
            fileName: `emp_${user_id}.${Helper.GetFileExtension(file)}`,
            fileType: file.type,
            fileBaseDir: "employeeImage",
            rID: Helper.UserLoginData().ID,
          },
          APIHelper.Header.json,
          new HttpParams()
        );
      },

      getSourceURL: (filename: string) => {
        return this.httpProvider.sendGetRequest(
          `/get_file_presigned_url?fileName=${filename}&fileBaseDir=employeeImage`,
          new HttpHeaders(),
          new HttpParams()
        )
      }

    },

    updatePayRate: (empID: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/employees/${empID}/admin-rate`, { ...body, rID: Helper.UserLoginData().ID, })
    },
  }

  public file = {
    put: (file: File, url: string) => {
      return this.fapi.raw.sendPostRequest<HttpResponse<any>>(
        url,
        file,
        new HttpHeaders({ "content-type": file.type }),
        new HttpParams()
      );
    },
    uploadURL: (file: File, id: number) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(
        '/get/presigned/upload',
        {
          fileName: `${id}.${Helper.GetFileExtension(file)}`,
          fileType: file.type,
          fileBaseDir: "employeeImage",
          rID: Helper.UserLoginData().ID,
        },
        APIHelper.Header.json,
        new HttpParams()
      );
    },
    getSourceURL: (filename: string, directory: string = "employeeImage") => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(
        `/get_file_presigned_url?fileName=${filename}&fileBaseDir=${directory}`,
        new HttpHeaders(),
        new HttpParams()
      ).pipe(
        map(values => values as HttpResponse<{ results: string, url: string }>)
      );
    }
  }

  public department = {

    getAll: () => {
      return this.httpProvider.sendGetRequest('/departments', new HttpHeaders(), new HttpParams());
    },

    getById: (id: number) => {
      return this.httpProvider.sendGetRequest(`/departments?ID=${id}`, new HttpHeaders(), new HttpParams());
    },

    add: (name: string) => {
      return this.httpProvider.sendPostRequest(
        '/departments/add',
        { Name: name, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    update: (name: string, id: number) => {
      return this.httpProvider.sendPostRequest(
        '/departments/update',
        { Name: name, ID: id, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    delete: (id: number) => {
      return this.httpProvider.sendPostRequest(
        '/departments/delete',
        { ID: id, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      );
    }

  }

  public designation = {

    getAll: () => {
      return this.httpProvider.sendGetRequest('/designations', new HttpHeaders(), new HttpParams());
    },

    getById: (dept_id: number) => {
      return this.httpProvider.sendGetRequest(`/designations?Department=${dept_id}`, new HttpHeaders(), new HttpParams());
    },

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/designations/add',
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    update: (body: Object, id: number) => {
      return this.httpProvider.sendPostRequest(
        '/designations/update',
        { ...body, ID: id, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    delete: (id: number) => {
      return this.httpProvider.sendPostRequest(
        '/designations/delete',
        { ID: id, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    }

  }

  public holiday = {

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/holiday/add',
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    update: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/holiday/update',
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    dalete: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/holiday/delete',
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    getAll: () => {
      return this.httpProvider.sendGetRequest(`/holiday`, new HttpHeaders(), new HttpParams());
    },

    getById: (id: number) => {
      return this.httpProvider.sendGetRequest(`/holiday?ID=${id}`, new HttpHeaders(), new HttpParams());
    }

  }

  public project = {

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/projects', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    update: (empId: number, projId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/employees/${empId}/projects/${projId}`, { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    delete: (empId: number, projId: number) => {
      return this.httpProvider.sendDeleteRequest(`/employees/${empId}/projects/${projId}?rID=${Helper.UserLoginData().ID}`, APIHelper.Header.json, new HttpParams());
    },


  }

  public leaveSetting = {

    get: () => {
      return this.httpProvider.sendGetRequest(
        '/leave-settings',
        new HttpHeaders(),
        new HttpParams()
      )
    },

    update: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/leave-settings/update',
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      )
    },

    resetLeaves: () => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/reset-leaves`, { rID: Helper.UserLoginData().ID });
    }

  }

  public contributions = {

    deductionBasis: {
      get: () => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(`/social-security-institutions`);
      },
      update: (SSS_Mode?: string, Pagibig_Mode?: string, Philhealth_Mode?: string) => {
        return this.fapi.sendPutRequest<HttpResponse<any>>(`/social-security-institutions`, { SSS_Mode, Pagibig_Mode, Philhealth_Mode, rID: Helper.UserLoginData().ID });
      }
    },

    pagibig: {

      add: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/pagibig/add', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      getAll: () => {
        return this.httpProvider.sendGetRequest('/pagibig', new HttpHeaders(), new HttpParams());
      },

      update: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/pagibig/update', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      delete: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/pagibig/delete', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },
    },

    sss: {

      add: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/sss/add', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      getAll: () => {
        return this.httpProvider.sendGetRequest('/sss', new HttpHeaders(), new HttpParams());
      },

      update: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/sss/update', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      delete: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/sss/delete', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },
    },

    philhealth: {

      add: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/philhealth/add', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      getAll: () => {
        return this.httpProvider.sendGetRequest('/philhealth', new HttpHeaders(), new HttpParams());
      },

      update: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/philhealth/update', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },

      delete: (body: Object[]) => {
        return this.httpProvider.sendPostRequest('/philhealth/delete', { values: body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
      },
    },

  }

  public payroll = {

    build: (body: Object, withAdminRate: boolean, withManagerRate: boolean) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(
        `/compute_salary?${withAdminRate ? '&withAdminRate=1' : ''}${withManagerRate ? '&withManagerRate=1' : ''}`,
        { ...body, rID: Helper.UserLoginData().ID, }
      );
    },
    getBuildList: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/payslips/batch-date`);
    },
    getPayslipByDate: (date: string, from: string, to: string, withAdminRate: boolean, withManagerRate: boolean) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(
        `/payslips?Date=${date}&CutoffPeriodFrom=${from}&CutoffPeriodTo=${to}${withAdminRate ? '&withAdminRate=1' : ''}${withManagerRate ? '&withManagerRate=1' : ''}`
      );
    },
    getByEmpId: {
      lastPayslip: (empId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(`/payslips?Employee=${empId}&index=-1`);
      },
      all: (empId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(`/payslips?Employee=${empId}`);
      },
    }
  }

  public leave = {

    getAll: () => {
      return this.httpProvider.sendGetRequest('/leaves', new HttpHeaders(), new HttpParams());
    },

    getByColumn: (body: Record<string, any>) => {
      return this.httpProvider.sendGetRequest(Helper.ToURLParams('/leaves', body), new HttpHeaders(), new HttpParams());
    },

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/leaves/add', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    update: (body: Object) => {
      return this.httpProvider.sendPostRequest('/leaves/update', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    delete: (body: Object) => {
      return this.httpProvider.sendPostRequest('/leaves/delete', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    approve: (body: Object) => {
      return this.httpProvider.sendPostRequest('/leaves/approve', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
  }

  public report = {

    contribution: {
      /**
       * DO NOT USE THIS.
       * @deprecated
       */
      getAll: () => {
        return this.httpProvider.sendGetRequest('/reports', new HttpHeaders(), new HttpParams())
      },

      government: (urlParams: Record<string, string>) => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(Helper.ToURLParams('/contributions', urlParams));
      },
    },

    cashbond: (year: string, month: string, per_page: string, employee_name: string) => this.fapi.path2nd.sendGetRequest<CashbondReportInterface>(
      Helper.ToURLParams('/api/v1/payslips/report/cashbond', { year, month, per_page, employee_name })
    ).pipe(
      map(values => values.body as CashbondReportInterface),
    ),

    deductions: (cutoff_from: string, cutoff_to: string, per_page: string, employee_name: string) => this.fapi.path2nd.sendGetRequest<DeductionReportInterface>(
      Helper.ToURLParams('/api/v1/payslips/report/deductions', { cutoff_from, cutoff_to, per_page, employee_name })
    ).pipe(
      map(values => values.body as DeductionReportInterface),
    ),

    thirteenth_month: (year: string, month: string, per_page: string, employee_name: string) => this.fapi.path2nd.sendGetRequest<ThirteenthMonthReportInterface>(
      Helper.ToURLParams('/api/v1/payslips/report/13th-month', { year, month, per_page, employee_name })
    ).pipe(
      map(values => values.body as ThirteenthMonthReportInterface),
    ),

    retirement_pay: (year: string, month: string, per_page: string, employee_name: string) => this.fapi.path2nd.sendGetRequest<RetirementPayReportInterface>(
      Helper.ToURLParams('/api/v1/payslips/report/retirement-pay', { year, month, per_page, employee_name })
    ).pipe(
      map(values => values.body as RetirementPayReportInterface),
    ),

    loans: (year: string, month: string, cutoff: string, per_page: string, employee_name: string) => this.fapi.path2nd.sendGetRequest<{}>(
      Helper.ToURLParams('/api/v1/payslips/report/loans', { year, month, cutoff, per_page, employee_name })
    ).pipe(
      map(values => values.body as LoanReportInterface),
    ),

  }

  public emp_caseManagement = {

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/employee/add', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    update: (uid: number, data: Object[]) => {
      return this.httpProvider.sendPostRequest('/employee/update', { Table: 'tbl_case_management', User: uid, Data: data, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    delete: (uid: number, ids: number[]) => {
      return this.httpProvider.sendPostRequest('/employee/delete', { Table: 'tbl_case_management', User: uid, Data: ids, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
  }

  public emp_awardService = {

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/employee/add', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    update: (uid: number, data: Object[]) => {
      return this.httpProvider.sendPostRequest('/employee/update', { Table: 'tbl_award_management', User: uid, Data: data, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    delete: (uid: number, ids: number[]) => {
      return this.httpProvider.sendPostRequest('/employee/delete', { Table: 'tbl_award_management', User: uid, Data: ids, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
  }

  public emp_training = {

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/employee/add', { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    update: (uid: number, data: any[]) => {
      return this.httpProvider.sendPostRequest('/employee/update', { Table: 'tbl_training', User: uid, Data: data, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },

    delete: (uid: number, ids: number[]) => {
      return this.httpProvider.sendPostRequest('/employee/delete', { Table: 'tbl_training', User: uid, Data: ids, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
  }

  public dashboard = {
    getAttendance: (uid: number, year: number) => {
      return this.httpProvider.sendPostRequest('/graph', { uid, year }, APIHelper.Header.json, new HttpParams());
    },
    getExpiringLicense: <T>(expiring: boolean = true) => {
      return this.fapi.sendGetRequest<HttpResponse<T>>(`/serial-numbers/report?expired-license=${Number(expiring)}`).pipe(
        map(values => values.body)
      )
    }
  }

  public client = {
    get: {
      all: () => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(`/agf-clients`, new HttpHeaders(), new HttpParams());
      },
      byId: (uid: number) => {
        return this.fapi.sendGetRequest<HttpResponse<any>>(`/agf-clients/${uid}`, new HttpHeaders(), new HttpParams());
      }
    },

    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/agf-clients`, { ...body, rID: Helper.UserLoginData().ID })
    },

    update: (clientId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/agf-clients/${clientId}`, { ...body, rID: Helper.UserLoginData().ID })
    },

    delete: (clientId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/agf-clients/${clientId}?rID=${Helper.UserLoginData().ID}`)
    }
  }

  public birthday = {
    getAll: () => {
      return this.httpProvider.sendGetRequest('/birthday-celebrants', new HttpHeaders(), new HttpParams());
    }
  }

  public downloadable = {
    getAll: () => {
      return this.httpProvider.sendPostRequest('/downloadable-forms/all', {}, new HttpHeaders(), new HttpParams());
    },
    delete: (file_name: string) => {
      return this.httpProvider.sendPostRequest('/downloadable-forms/delete', { file_name, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    upload: {
      getUploadURL: (file: File, user_id: number) => {
        return this.httpProvider.sendPostRequest(
          '/get/presigned/upload',
          {
            fileName: file.name,
            fileType: file.type,
            fileBaseDir: "downloadable-forms",
            rID: Helper.UserLoginData().ID,
          },
          APIHelper.Header.json,
          new HttpParams()
        );
      },

      puFile: (file: File, presignedURL: string) => {
        return this.httpProvider.sendPutRequest_Raw(
          presignedURL,
          file,
          new HttpHeaders({ "content-type": file.type }),
          new HttpParams()
        );
      },

      getSourceURL: (filename: string) => {
        return this.httpProvider.sendGetRequest(
          `/get_file_presigned_url?fileName=${filename}&fileBaseDir=downloadable-forms`,
          new HttpHeaders(),
          new HttpParams()
        )
      }
    }
  }

  public audit_logs = {
    getAll: () => {
      return this.httpProvider.sendGetRequest('/audits', new HttpHeaders(), new HttpParams());
    }
  }

  public applicants = {
    getAll: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>('/applicants');
    },

    getSpecific: (id: number) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/applicants/${id}`);
    }
  }

  public violations = {
    getAll: () => {
      return this.httpProvider.sendGetRequest('/violations', new HttpHeaders(), new HttpParams());
    }
  }

  public application_status = {
    updateRemarks: (body: Object) => {
      return this.httpProvider.sendPostRequest('/employee/update', { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
  }

  public employee_changePassword(body: Object) {
    return this.httpProvider.sendPostRequest('/change-password', { ...body }, new HttpHeaders(), new HttpParams());
  }

  public employee_resetPasswordLink(body: Object) {
    return this.httpProvider.sendPostRequest('/send-email', { ...body }, new HttpHeaders(), new HttpParams());
  }

  public employee_resetPassword(body: Object) {
    return this.httpProvider.sendPostRequest('/reset-password', { ...body }, new HttpHeaders(), new HttpParams());
  }

  public letterBodyTemplates = {
    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/template/add', { ...body }, new HttpHeaders(), new HttpParams());
    },
    update: (body: Object) => {
      return this.httpProvider.sendPostRequest('/template/update', { ...body }, new HttpHeaders(), new HttpParams());
    },
    delete: (body: Object) => {
      return this.httpProvider.sendPostRequest('/template/delete', { ...body }, new HttpHeaders(), new HttpParams());
    },
    getAllByUser: () => {
      return this.httpProvider.sendGetRequest(`/template?get=all`, new HttpHeaders(), new HttpParams());
    }
  }

  public trainings = {
    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/trainings', { ...body, rID: Helper.UserLoginData().ID }, new HttpHeaders(), new HttpParams());
    },
    update: (trainingID: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/trainings/${trainingID}`, { ...body, rID: Helper.UserLoginData().ID }, new HttpHeaders(), new HttpParams());
    },
    delete: (trainingID: number) => {
      return this.httpProvider.sendDeleteRequest(`/trainings/${trainingID}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    getAll: () => {
      return this.httpProvider.sendGetRequest(`/trainings`, new HttpHeaders(), new HttpParams());
    },
    getByID: (trainingID: number) => {
      return this.httpProvider.sendGetRequest(`/trainings/${trainingID}`, new HttpHeaders(), new HttpParams());
    },
    report: (from?: string, to?: string, status?: string) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/trainings/reports?${from ? 'From=' + from + '&' : ''}${to ? 'To=' + to + '&' : ''}${status ? 'Status=' + status : ''}`, new HttpHeaders(), new HttpParams());
    }
  }

  public training_schedule = {
    request: (trainingID: number, scheduleID: number) => {
      return this.httpProvider.sendPostRequest(`/trainings/${trainingID}/schedules/${scheduleID}/requests`, { Requested_By: Helper.UserLoginData().ID, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    add: (trainingID: number, body: Object) => {
      return this.httpProvider.sendPostRequest(`/trainings/${trainingID}/schedules`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    update: (trainingID: number, scheduleID: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/trainings/${trainingID}/schedules/${scheduleID}`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    changeSatus: (trainingId: number, scheduleId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/trainings/${trainingId}/schedules/${scheduleId}/requests`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    delete: (trainingID: number, scheduleID: number) => {
      return this.httpProvider.sendDeleteRequest(`/trainings/${trainingID}/schedules/${scheduleID}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    report: (trainingId: number, from?: string, to?: string, status?: string) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/trainings/${trainingId}/reports?${from ? 'From=' + from + '&' : ''}${to ? 'To=' + to + '&' : ''}${status ? 'Status=' + status : ''}`, new HttpHeaders(), new HttpParams());
    },
    get: {
      all: () => {
        return this.httpProvider.sendGetRequest(`/schedules`, new HttpHeaders(), new HttpParams());
      },
      byTrainingId: (trainingId: number) => {
        return this.httpProvider.sendGetRequest(`/trainings/${trainingId}/schedules`, new HttpHeaders(), new HttpParams());
      },
      byScheduleId: (trainingId: number, scheduleId: number) => {
        return this.httpProvider.sendGetRequest(`/trainings/${trainingId}/schedules/${scheduleId}}`, new HttpHeaders(), new HttpParams());
      },
      /**
       * @deprecated
       */
      byDepartmentId: (departmentId: number) => {
        return this.httpProvider.sendGetRequest(`/schedules?department=${departmentId}`, new HttpHeaders(), new HttpParams());
      },
      notTaken: (departmentId: number) => {
        return this.httpProvider.sendGetRequest(`/schedules?get=available&NotTakenBy=${Helper.UserLoginData().ID}&department=${departmentId}`, new HttpHeaders(), new HttpParams());
      },
      requests: (trainingId: number, scheduleId: number) => {
        return this.httpProvider.sendGetRequest(`/trainings/${trainingId}/schedules/${scheduleId}/requests`, new HttpHeaders(), new HttpParams());
      }
    },
  }

  public infraction = {
    add: (empId: number, body: Object) => {
      return this.httpProvider.sendPostRequest(`/employees/${empId}/infractions`, { ...body, rID: Helper.UserLoginData().ID }, new HttpHeaders(), new HttpParams());
    },
    update: (empId: number, infractionId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/employees/${empId}/infractions/${infractionId}`, { ...body, rID: Helper.UserLoginData().ID }, new HttpHeaders(), new HttpParams());
    },
    delete: (empId: number, infractionId: number) => {
      return this.httpProvider.sendDeleteRequest(`/employees/${empId}/infractions/${infractionId}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    updateWithProgress: (empId: number, infractionId: number, progressId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/employees/${empId}/infractions/${infractionId}/progress/${progressId}`, { ...body, rID: Helper.UserLoginData().ID }, new HttpHeaders(), new HttpParams());
    },
  }

  public asset = {
    get: {
      all: () => {
        return this.fapi.sendGetRequest<HttpResponse<any>>('/assets', new HttpHeaders(), new HttpParams())
      },
      byID: (id: number) => {
        return this.httpProvider.sendGetRequest(`/assets/${id}`, new HttpHeaders(), new HttpParams())
      },
      byParams: (params: Record<string, any>) => {
        return this.fapi.sendGetRequest<HttpResponse<APIResponse<AssetInterface>>>(Helper.ToURLParams('/assets', params));
      }
    },
    add: (body: Object) => {
      return this.httpProvider.sendPostRequest('/assets', { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams())
    },
    update: (assetId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/assets/${assetId}`, { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams())
    },
    delete: (assetId: number) => {
      return this.httpProvider.sendDeleteRequest(`/assets/${assetId}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    report: (params: Record<string, string>) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(Helper.ToURLParams('/assets/reports', params), new HttpHeaders(), new HttpParams())
    },
    reportAll: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/assets/reports`, new HttpHeaders(), new HttpParams())
    },
  }

  public asset_serial = {
    getSerials_ByAssetID: (assetID: number) => {
      return this.httpProvider.sendGetRequest(`/assets/${assetID}/serial-numbers`, new HttpHeaders(), new HttpParams())
    },
    add: (assetId: number, body: Object) => {
      return this.httpProvider.sendPostRequest(`/assets/${assetId}/serial-numbers`, { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
    update: (assetId: number, serialId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(`/assets/${assetId}/serial-numbers/${serialId}`, { ...body, rID: Helper.UserLoginData().ID, }, APIHelper.Header.json, new HttpParams());
    },
    delete: (assetId: number, serialId: number) => {
      return this.httpProvider.sendDeleteRequest(`/assets/${assetId}/serial-numbers/${serialId}?rID=${Helper.UserLoginData().ID}`, APIHelper.Header.json, new HttpParams());
    },
    report: (assetId: number, from: string, to: string) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/assets/${assetId}/reports?From=${from}&To=${to}`, new HttpHeaders(), new HttpParams())
    },
    reportAll: (assetId: number) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/assets/${assetId}/reports`, new HttpHeaders(), new HttpParams())
    }
  }

  public consumable_assets = {
    get: {
      all: () => {
        return this.fapi.sendGetRequest<HttpResponse<any>>('/consumable-assets');
      },
    },
  }

  public manage_deductions = {
    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/deductions`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    update: (empId: number, deductionId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/employees/${empId}/deductions/${deductionId}`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    delete: (empId: number, deductionId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/employees/${empId}/deductions/${deductionId}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    get: (empId: number) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/employees/${empId}/deductions`, new HttpHeaders(), new HttpParams());
    }
  }

  public manage_allowance = {
    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/incentives`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    update: (empId: number, incentiveId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/employees/${empId}/incentives/${incentiveId}`, { ...body, rID: Helper.UserLoginData().ID, }, new HttpHeaders(), new HttpParams());
    },
    delete: (empId: number, incentiveId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/employees/${empId}/incentives/${incentiveId}?rID=${Helper.UserLoginData().ID}`, new HttpHeaders(), new HttpParams());
    },
    get: (empId: number) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/employees/${empId}/incentives`, new HttpHeaders(), new HttpParams());
    }

  }

  public dtr = {

    getAll: () => {
      return this.httpProvider.sendGetRequest('/dtr', new HttpHeaders(), new HttpParams())
    },

    getCustomParam: (body: Record<string, any>) => {
      return this.httpProvider.sendGetRequest(Helper.ToURLParams('/dtr', body), new HttpHeaders(), new HttpParams())
    },

    add: (body: Object) => {
      return this.httpProvider.sendPostRequest(
        '/dtr/add',
        { values: body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      );
    },

    update: (empId: number, dtrId: number, body: Object) => {
      return this.httpProvider.sendPutRequest(
        `/employees/${empId}/dtr/${dtrId}`,
        { ...body, rID: Helper.UserLoginData().ID, },
        APIHelper.Header.json,
        new HttpParams()
      );
    },

    changeStatus: (empId: number, dtrId: number, status: string) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/employees/${empId}/dtr/${dtrId}`, { Status: status, rID: Helper.UserLoginData().ID });
    },

    bulkChangeStatus: (dtr: { status: string, id: string }[]) => {
      return this.fapi.path2nd.sendPutRequest<HttpResponse<any>>(`/bulk-update-dtr`, { dtr, rID: Helper.UserLoginData().ID });
    },

  }

  public detachment = {
    getAll: (withAdminRate: boolean = false, withManagerialRate: boolean = false) => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/detachments?${withAdminRate ? '&withAdminRate=1' : ''}${withManagerialRate ? '&withManagerRate=1' : ''}`);
    },

    getAllBasic: () => {
      return this.fapi.sendGetRequest<HttpResponse<DetachmentBasicInterface[]>>('/detachments-list').pipe(
        map(values => values.body)
      )
    },

    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/detachments`, { ...body, rID: Helper.UserLoginData().ID, });
    },

    update: (detachmentId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/detachments/${detachmentId}`, { ...body, rID: Helper.UserLoginData().ID, });
    },

    delete: (detachmentId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/detachments/${detachmentId}?rID=${Helper.UserLoginData().ID}`);
    },
  }

  public manage_vacancySources = {
    getAll: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/company_discovery_methods`);
    },

    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/company_discovery_methods`, { ...body, rID: Helper.UserLoginData().ID });
    },

    update: (mId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/company_discovery_methods/${mId}`, { ...body, rID: Helper.UserLoginData().ID });
    },

    delete: (mId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/company_discovery_methods/${mId}?rID=${Helper.UserLoginData().ID}`);
    },

  }

  public certificateTemplate = {
    get: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/template?ID=43`);
    },
    update: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/template/update`, { ID: 43, Placeholders: body, rID: Helper.UserLoginData().ID });
    }
  }

  public cities = {
    get: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>('/cities');
    }
  }

  public suppliers = {
    getAll: () => {
      return this.fapi.sendGetRequest<HttpResponse<any>>(`/suppliers`);
    },

    add: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/suppliers`, { ...body, rID: Helper.UserLoginData().ID });
    },

    update: (id: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/suppliers/${id}`, { ...body, rID: Helper.UserLoginData().ID });
    },

    delete: (id: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/suppliers/${id}?rID=${Helper.UserLoginData().ID}`);
    },
  }

  public assetRequest = {
    get: {
      prBy: <T>(userId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests?RequestedBy=${userId}`)
          .pipe(map(values => values.body));
      },
      prId: <T>(prId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-request/${prId}`)
          .pipe(map(values => values.body));
      },
      header: <T>() => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests/dashboard-report`)
          .pipe(map(values => values.body));
      },
      items: {
        approved: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/items-requested/approved`)
            .pipe(map(values => values.body));
        },
        pending: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/items-requested/pending`)
            .pipe(map(values => values.body));

        },
        forReceiving: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/items-requested/to-receive`)
            .pipe(map(values => values.body));
        },
      },
      pr: {
        pending: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests/pending`)
            .pipe(map(values => values.body));
        },
        approved: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests/approved`)
            .pipe(map(values => values.body));
        },
        disapproved: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests/disapproved`)
            .pipe(map(values => values.body));
        },
        forReceiving: <T>() => {
          return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/asset-requests/to-receive`)
            .pipe(map(values => values.body));
        },
      },
    },
    add: <T>(body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<T>>(`/api/v1/request-asset`, {
        ...body, rID: Helper.UserLoginData().ID
      }).pipe(map(values => values.body));
    },
    update: <T>(assetRequestId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/asset-request/${assetRequestId}`, {
        ...body, rID: Helper.UserLoginData().ID
      }).pipe(map(values => values.body));
    },
    updateStatus: {
      approvePr: <T>(assetRequestId: number) => {
        return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/asset-request/${assetRequestId}/approved`, {
          rID: Helper.UserLoginData().ID
        }).pipe(map(values => values.body));
      },
      disapprovePr: <T>(assetRequestId: number) => {
        return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/asset-request/${assetRequestId}/disapproved`, {
          rID: Helper.UserLoginData().ID
        }).pipe(map(values => values.body));
      },
      approveSuppier: <T>(itemRequestId: number, supplierId: number) => {
        return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/item-request/${itemRequestId}/supplier/${supplierId}/approve`, {
          rID: Helper.UserLoginData().ID
        }).pipe(map(values => values.body));
      },
      receive: <T>(itemId: number, Quantity: number) => {
        return this.fapi.sendPostRequest<HttpResponse<T>>(`/api/v1/item-request/${itemId}/receive`, {
          Quantity, rID: Helper.UserLoginData().ID
        }).pipe(map(values => values.body));
      },
      return: <T>(assetId: number, serialId: number, Quantity: number, Remarks: string) => {
        return this.fapi.sendPostRequest<HttpResponse<T>>(`/asset/${assetId}/serial-number/${serialId}/return`, {
          Remarks, Quantity, rID: Helper.UserLoginData().ID
        }).pipe(map(values => values.body));
      },
    }
  };

  orgChart = {
    get: () => {
      return this.fapi.sendGetRequest<HttpResponse<IEmployee[]>>(`/api/v1/organizational-chart`).pipe(
        map(valuues => valuues.body),
      )
    },

    addHead: (body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/api/v1/organizational-chart`, { Parent: null, ...body, rID: Helper.UserLoginData().ID }).pipe(
        map(values => values.body),
      );
    },

    addChild: (parentId: number, body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<any>>(`/api/v1/organizational-chart`, { Parent: [parentId], ...body, rID: Helper.UserLoginData().ID }).pipe(
        map(values => values.body),
      );
    },

    updateNode: (nodeId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<any>>(`/api/v1/organizational-chart/${nodeId}`, { ...body, rID: Helper.UserLoginData().ID }).pipe(
        map(values => values.body),
      );
    },

    deleteNode: (nodeId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<any>>(`/api/v1/organizational-chart/${nodeId}?rID=${Helper.UserLoginData().ID}`).pipe(
        map(values => values.body),
      );
    },

  }


  public itemCode = {
    get: {
      single: <T>(itemCodeId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/item-code/${itemCodeId}`,
        ).pipe(map(values => values.body));
      },
      all: <T>() => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/item-codes`,
        ).pipe(map(values => values.body));
      }
    },
    add: <T>(body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<T>>(`/api/v1/item-code`,
        { ...body, rID: Helper.UserLoginData().ID }
      ).pipe(map(values => values.body));
    },
    update: <T>(itemCodeId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/item-code/${itemCodeId}`,
        { ...body, rID: Helper.UserLoginData().ID }
      ).pipe(map(values => values.body));
    },
    delete: <T>(itemCodeId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<T>>(`/api/v1/item-code/${itemCodeId}?rID=${Helper.UserLoginData().ID}`,

      ).pipe(map(values => values.body));
    },
  }

  public itemCategory = {
    get: {
      single: <T>(itemCateforyId: number) => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/item-category/${itemCateforyId}`,
        ).pipe(map(values => values.body));
      },
      all: <T>() => {
        return this.fapi.sendGetRequest<HttpResponse<T>>(`/api/v1/item-categories`,
        ).pipe(map(values => values.body));
      }
    },
    add: <T>(body: Object) => {
      return this.fapi.sendPostRequest<HttpResponse<T>>(`/api/v1/item-category`,
        { ...body, rID: Helper.UserLoginData().ID }
      ).pipe(map(values => values.body));
    },
    update: <T>(itemCateforyId: number, body: Object) => {
      return this.fapi.sendPutRequest<HttpResponse<T>>(`/api/v1/item-category/${itemCateforyId}`,
        { ...body, rID: Helper.UserLoginData().ID }
      ).pipe(map(values => values.body));
    },
    delete: <T>(itemCateforyId: number) => {
      return this.fapi.sendDeleteRequest<HttpResponse<T>>(`/api/v1/item-category/${itemCateforyId}?rID=${Helper.UserLoginData().ID}`,

      ).pipe(map(values => values.body));
    },
  }

  public readonly yearlyEmployeeSalary = {
    getYearChoices: (empId: number) => {
      return this.fapi.sendGetRequest<HttpResponse<{ result: number[] }>>(`/employee-payslips-years?emp_id=${empId}`).pipe(
        map(values => values.body?.result),
      );
    },
    tableSummary: (empId: number, year: number) => {
      return this.fapi.sendGetRequest<HttpResponse<{ result: EmployeeYearSalarySummaryInterface }>>(`/payroll-yearly-summary?emp_id=${empId}&year=${year}`).pipe(
        map(values => values.body?.result),
      );
    },
  }

  public readonly violationCategory = {
    get: () => this.fapi.path2nd.sendGetRequest<{ data: ViolationCategoryInterface[] }>(`/api/v1/case/categories`).pipe
      (map(values => values.body?.data)),
    add: (body: Object) => this.fapi.path2nd.sendPostRequest<ViolationCategoryInterface>(`/api/v1/case/category`, { ...body, rID: Helper.UserLoginData().ID }).pipe
      (map(values => values.body)),
    update: (id: number, body: Object) => this.fapi.path2nd.sendPutRequest<ViolationCategoryInterface>(`/api/v1/case/category/${id}`, { ...body, rID: Helper.UserLoginData().ID }).pipe
      (map(values => values.body)),
    delete: (id: number) => this.fapi.path2nd.sendDeleteRequest<ViolationCategoryInterface>(`/api/v1/case/category/${id}?rID=${Helper.UserLoginData().ID}`).pipe
      (map(values => values.body)),
  }

  public readonly violationCodes = {
    get: () => this.fapi.path2nd.sendGetRequest<{ data: ViolationCodeInterface[] }>(`/api/v1/case/codes`).pipe
      (map(values => values.body?.data)),
    add: (body: Object) => this.fapi.path2nd.sendPostRequest<ViolationCodeInterface>(`/api/v1/case/code`, { ...body, rID: Helper.UserLoginData().ID }).pipe
      (map(values => values.body)),
    update: (id: number, body: Object) => this.fapi.path2nd.sendPutRequest<ViolationCodeInterface>(`/api/v1/case/code/${id}`, { ...body, rID: Helper.UserLoginData().ID }).pipe
      (map(values => values.body)),
    delete: (id: number) => this.fapi.path2nd.sendDeleteRequest<ViolationCodeInterface>(`/api/v1/case/code/${id}?rID=${Helper.UserLoginData().ID}`).pipe
      (map(values => values.body)),
  }

  public readonly maintenance = {
    check: () => this.fapi.sendGetRequest<HttpResponse<{ whole_system_settings: "Active" | string }>>(`/api/v1/system-settings`).pipe(
      map(values => values.body)
    ),
  }

}