import { Component, ViewEncapsulation } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { CommonService } from './shared/common/common.service';
import { routes, url } from './core/core.index';
import { environment } from 'src/environments/environment';
import { Helper } from './core/helpers/methods/Helper';
import { filter, finalize, map, Subscription, tap } from 'rxjs';
import { RoutesAccess } from './core/helpers/routes/routes-access';
import { LstorageService } from './core/services/lstorage/lstorage.service';
import { WebStorage } from './core/services/storage/web.storage';
import { ApiService } from './shared/api.service';
import { HttpResponse } from '@angular/common/http';
import { UserInfo_AllInfoInterface } from './shared/interface/global-interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AppComponent {
  title = 'template';
  base = '';
  page = '';
  last = '';

  constructor(
    private common: CommonService,
    private router: Router,
    private storage: LstorageService,
    private webStorage: WebStorage,
    private _api: ApiService,
  ) {
    console.log("Running in ", environment.app_version, '241022 | ' + "Prod: " + environment.production);

    // Handle access in every page
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((raw) => {
      if (!Helper.userIsClient() && Helper.UserLoginData().ID >= 0) {
        const subs: Subscription = this._api.employee.getDetiled(Helper.UserLoginData().ID).pipe(
          map(values => (values as HttpResponse<{ result: UserInfo_AllInfoInterface }>).body?.result),
          tap(values => {
            if (!values) return;
            webStorage.UpdateUserLoginData(values);
          }),
          finalize(() => subs.unsubscribe()),
        ).subscribe();
      }
      else if (Helper.userIsClient()) {
        webStorage.UpdateUserLoginDataAsClient();
      }
    });

    this.common.base.subscribe((res: string) => {
      this.base = res;
    });
    this.common.page.subscribe((res: string) => {
      this.page = res;
    });
    this.common.last.subscribe((res: string) => {
      this.last = res;
    });
    this.router.events.subscribe((data: RouterEvent) => {
      if (data instanceof NavigationStart) {
        this.getRoutes(data);
      }
    })
  }

  public getRoutes(events: url) {
    const splitVal = events.url.split('/');
    this.common.base.next(splitVal[1]);
    this.common.page.next(splitVal[2]);
    this.common.last.next(splitVal[3]);
  }
}