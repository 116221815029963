import { DesingationAccessInterface } from "src/app/shared/interface/global-interface";
import { Helper } from "./Helper";

export interface UserAccessInterface {
  id: string;
  moduleName: string;
  details?: string,
  label?: string,
  actions: {
    id: string,
    actionName: string,
    class?: string,
    details?: string,
    label?: string,
  }[];
}

export const HRISACM: UserAccessInterface[] = [
  {
    id: "m1",
    moduleName: "website-header",
    actions: [
      { id: "m1-1", actionName: "option-to-view-own-profile" },
      { id: "m1-2", actionName: "option-to-view-change-password" },
      { id: "m1-3", actionName: "option-to-view-about" },
    ]
  },
  {
    id: "m2",
    moduleName: "dashboard-module",
    details: "Select only one 'View as' for dashboard.",
    actions: [
      { id: "m2-1", actionName: "view-as-admin-dashboard" },
      { id: "m2-2", actionName: "view-as-client-dashboard" },
      { id: "m2-3", actionName: "view-as-employee-dashboard" },
      { id: "m2-4", actionName: "view-as-applicant-dashboard", class: "mb-4" },

      { id: "m2-5", actionName: "view-header" },
      { id: "m2-6", actionName: "view-cards" },
      { id: "m2-7", actionName: "view-upcoming-holidays" },
      { id: "m2-8", actionName: "view-performance" },
    ]
  },
  {
    id: "m3",
    moduleName: "employee-module",
    actions: [
      { id: "m3-1", actionName: "view-own-profile" },
      { id: "m3-2", actionName: "update-own-profile", class: "mb-4" },

      { id: "m3-3", actionName: "view-profile-tab" },
      { id: "m3-4", actionName: "update-in-profile-tab" },
      { id: "m3-5", actionName: "view-projects-tab" },
      { id: "m3-6", actionName: "update-in-projects-tab" },
      { id: "m3-7", actionName: "view-bank-and-statutory-tab" },
      { id: "m3-8", actionName: "update-in-bank-and-statutory-tab" },
      { id: "m3-9", actionName: "view-deduction-and-allowance-tab" },
      { id: "m3-10", actionName: "update-in-deduction-and-allowance-tab" },
      { id: "m3-11", actionName: "view-assets-tab" },
      { id: "m3-12", actionName: "update-in-assets-tab" },
      { id: "m3-13", actionName: "view-DTR-tab" },
      { id: "m3-14", actionName: "update-in-DTR-tab" },
      { id: "m3-15", actionName: "view-infraction-tab" },
      { id: "m3-16", actionName: "update-in-infraction-tab" },
      { id: "m3-17", actionName: "view-awards-tab" },
      { id: "m3-18", actionName: "update-in-awards-tab" },
      { id: "m3-19", actionName: "view-training-tab" },
      { id: "m3-20", actionName: "update-in-training-tab" },
      { id: "m3-21", actionName: "view-performance-tab" },
      { id: "m3-22", actionName: "view-hiring-process-tab", class: "mb-4" },
      { id: "m3-23", actionName: "update-hiring-process-tab", class: "mb-4" },

      { id: "m3-24", actionName: "view-own-leave" },
      { id: "m3-25", actionName: "update-own-leave" },
      { id: "m3-26", actionName: "delete-own-leave", class: "mb-4" },

      { id: "m3-27", actionName: "view-employee-list" },
      { id: "m3-28", actionName: "view-other-employee-profile" },
      { id: "m3-29", actionName: "update-other-employee-profile" },
      { id: "m3-30", actionName: "delete-other-employee-profile", class: "mb-4" },

      { id: "m3-31", actionName: "view-applicants" },
      { id: "m3-32", actionName: "view-applicant-profile" },
      { id: "m3-33", actionName: "update-applicant-profile" },
      { id: "m3-34", actionName: "delete-applicant-profile", class: "mb-4" },

      { id: "m3-35", actionName: "view-leave-requests" },
      { id: "m3-36", actionName: "update-leave-request", class: "mb-4" },

      { id: "m3-37", actionName: "view-training-list" },
      { id: "m3-38", actionName: "add-training-item", class: "mb-4" },

      { id: "m3-39", actionName: "view-training-logs" },
      { id: "m3-40", actionName: "view-training-certification" },
      { id: "m3-41", actionName: "update-training-certification", class: "mb-4" },

      { id: "m3-42", actionName: "view-training-schedules" },
      { id: "m3-43", actionName: "view-training-schedule-logs" },
      { id: "m3-44", actionName: "update-training-item" },
      { id: "m3-45", actionName: "delete-training-item", class: "mb-4" },

      { id: "m3-46", actionName: "add-training-schedule" },
      { id: "m3-47", actionName: "update-training-schedule" },
      { id: "m3-48", actionName: "cancel-training-schedule" },
      { id: "m3-49", actionName: "delete-training-schedule", class: "mb-4" },

      { id: "m3-50", actionName: "view-training-paticipants" },
      { id: "m3-51", actionName: "accept-training-paticipant" },
      { id: "m3-52", actionName: "decline-training-paticipant", class: "mb-4" },

      { id: "m3-53", actionName: "view-employee-attendace" },
      { id: "m3-54", actionName: "update-employee-attendace" },

      { id: "m3-55", actionName: "update-in-employee-profile-to-be-admin-payroll", details: "Allow this designation to update the the profile of an employee and set that profile to be tagged as \"Is Admin Payroll\"." },
    ]
  },
  {
    id: "m4",
    moduleName: "downloadable-module",
    actions: [
      { id: "m4-1", actionName: "view-downloadable-list" },
      { id: "m4-2", actionName: "add-downloadables" },
      { id: "m4-3", actionName: "delete-downloadables" },
    ]
  },
  {
    id: "m5",
    moduleName: "clients-module",
    actions: [
      { id: "m5-1", actionName: "view-client-list" },
      { id: "m5-2", actionName: "view-client-profile" },
      { id: "m5-3", actionName: "add-clients" },
      { id: "m5-4", actionName: "update-clients" },
      { id: "m5-5", actionName: "delete-clients", class: "mb-4" },

      { id: "m5-6", actionName: "view-detachment-list" },
      { id: "m5-7", actionName: "add-detachment" },
      { id: "m5-8", actionName: "update-detachment" },
      { id: "m5-9", actionName: "delete-detachment", class: "mb-4" },

      { id: "m5-10", actionName: "view-and-edit-admin-rate", details: "View and update admin rate in detachments." },
      { id: "m5-11", actionName: "view-and-edit-managerial-rate", class: "mb-4", details: "View and update managerial rate in detachments." },

      { id: "m5-12", actionName: "approve-DTR", details: "Dashboard must be'View as Client' to approve DTR." },
      { id: "m5-13", actionName: "disapprove-DTR", class: "mb-4", details: "Dashboard must be'View as Client' to disapprove DTR." },
    ]
  },
  {
    id: "m6",
    moduleName: "payroll-module",
    actions: [
      { id: "m6-1", actionName: "view-own-payslip" },
      { id: "m6-2", actionName: "view-other-payslip" },
      { id: "m6-3", actionName: "view-built-payroll" },
      { id: "m6-4", actionName: "build-payroll", class: "mb-4" },

      { id: "m6-5", actionName: "view-sss-table" },
      { id: "m6-6", actionName: "update-sss-table", class: "mb-4" },

      { id: "m6-7", actionName: "view-pagibig-table" },
      { id: "m6-8", actionName: "update-pagibig-table", class: "mb-4" },

      { id: "m6-9", actionName: "view-philhealth-table" },
      { id: "m6-10", actionName: "update-philhealth-table", class: "mb-4" },

      { id: "m6-11", actionName: "view-payroll-reports" },
      { id: "m6-12", actionName: "view-13th-month-payroll-report", label: "Payroll reports page: " },
      { id: "m6-13", actionName: "view-cashbond-payroll-report", label: "Payroll reports page: " },
      { id: "m6-14", actionName: "view-deductions-payroll-report", label: "Payroll reports page: " },
      { id: "m6-15", actionName: "view-government-contribution-payroll-report", label: "Payroll reports page: " },
      { id: "m6-16", actionName: "view-loans-payroll-report", label: "Payroll reports page: " },
      { id: "m6-17", actionName: "view-retirement-pay-payroll-report", class: "mb-4", label: "Payroll reports page: " },

      { id: "m6-18", actionName: "add-multiple-deductions", class: "mb-4" },

      { id: "m6-19", actionName: "mark-designation-as-admin-type", details: "If checked, pay slips and rate are hidden to other designation." },
      { id: "m6-20", actionName: "mark-designation-as-managerial-type", class: "mb-4", details: "If checked, pay slips and rate are hidden to other designation." },

      { id: "m6-21", actionName: "view-and-edit-admin-type", details: "Allow this designation to view and update admin type designations." },
      { id: "m6-22", actionName: "view-and-edit-managerial-type", class: "mb-4", details: "Allow this designation to view and update managerial type designations." },]
  },
  {
    id: "m7",
    moduleName: "assets-module",
    actions: [
      { id: "m7-1", actionName: "view-own-purchase-request-list" },
      { id: "m7-2", actionName: "add-own-purchase-request" },
      { id: "m7-3", actionName: "update-own-purchase-request" },
      { id: "m7-4", actionName: "delete-own-purchase-request", class: "mb-4" },

      { id: "m7-5", actionName: "view-own-purchase-request" },
      { id: "m7-6", actionName: "view-and-update-other-purchase-request", class: "mb-4" },

      { id: "m7-7", actionName: "view-item-code-list" },
      { id: "m7-8", actionName: "add-item-codes" },
      { id: "m7-9", actionName: "update-item-codes" },
      { id: "m7-10", actionName: "delete-item-codes", class: "mb-4" },

      { id: "m7-11", actionName: "view-item-category-list" },
      { id: "m7-12", actionName: "add-item-categories" },
      { id: "m7-13", actionName: "update-item-categories" },
      { id: "m7-14", actionName: "delete-item-categories", class: "mb-4" },

      { id: "m7-15", actionName: "view-pending-purchase-request" },
      { id: "m7-16", actionName: "update-pending-purchase-request" },
      { id: "m7-17", actionName: "view-approved-purchase-request" },
      { id: "m7-18", actionName: "update-approved-purchase-request" },
      { id: "m7-19", actionName: "view-for-receiving-purchase-request" },
      { id: "m7-20", actionName: "update-for-receiving-purchase-request", class: "mb-4" },

      { id: "m7-21", actionName: "view-supplier-list" },
      { id: "m7-22", actionName: "add-suppliers" },
      { id: "m7-23", actionName: "update-suppliers" },
      { id: "m7-24", actionName: "delete-suppliers", class: "mb-4" },

      { id: "m7-25", actionName: "view-asset-list" },
      { id: "m7-26", actionName: "view-asset-details" },
      { id: "m7-27", actionName: "update-asset-details", class: "mb-4" },
    ]
  },
  {
    id: "m8",
    moduleName: "administration-module",
    actions: [
      { id: "m8-1", actionName: "view-vacancy-source-list" },
      { id: "m8-2", actionName: "add-vacancy-source" },
      { id: "m8-3", actionName: "update-vacancy-source" },
      { id: "m8-4", actionName: "delete-vacancy-source", class: "mb-4" },

      { id: "m8-5", actionName: "view-department-list" },
      { id: "m8-6", actionName: "add-department" },
      { id: "m8-7", actionName: "update-department" },
      { id: "m8-8", actionName: "delete-department", class: "mb-4" },

      { id: "m8-9", actionName: "view-designation-list" },
      { id: "m8-10", actionName: "add-designation" },
      { id: "m8-11", actionName: "update-designation" },
      { id: "m8-12", actionName: "delete-designation", class: "mb-4" },

      { id: "m8-13", actionName: "view-holiday-list" },
      { id: "m8-14", actionName: "add-holiday" },
      { id: "m8-15", actionName: "update-holiday" },
      { id: "m8-16", actionName: "delete-holiday", class: "mb-4" },

      { id: "m8-17", actionName: "view-infraction-template-list" },
      { id: "m8-18", actionName: "add-infraction-template" },
      { id: "m8-19", actionName: "update-infraction-template" },
      { id: "m8-20", actionName: "delete-infraction-template", class: "mb-4" },

      { id: "m8-21", actionName: "view-violoation-code-list" },
      { id: "m8-22", actionName: "add-violoation-code" },
      { id: "m8-23", actionName: "update-violoation-code" },
      { id: "m8-24", actionName: "delete-violoation-code", class: "mb-4" },

      { id: "m8-25", actionName: "view-violoation-category-list" },
      { id: "m8-26", actionName: "add-violoation-category" },
      { id: "m8-27", actionName: "update-violoation-category" },
      { id: "m8-28", actionName: "delete-violoation-category", class: "mb-4" },

      { id: "m8-29", actionName: "view-leave-settings" },
      { id: "m8-30", actionName: "update-leave-settings", class: "mb-4" },

      { id: "m8-31", actionName: "view-audit-logs" },
    ]
  },
  {
    id: "m9",
    moduleName: "organization-chart-module",
    actions: [
      { id: "m9-1", actionName: "view-organization-chart" },
      { id: "m9-2", actionName: "add-organization-cell" },
      { id: "m9-3", actionName: "update-organization-cell" },
      { id: "m9-4", actionName: "delete-organization-cell" },
    ]
  },
  {
    id: "m10",
    moduleName: "menu-bar-module",
    actions: [
      { id: "m10-1", label: "1", actionName: "Main" },

      { id: "m10-2", label: "1.1", actionName: "Main>Dashboard", class: "mx-3" },

      { id: "m10-3", label: "1.1.1", actionName: "Main>Dashboard>Admin", details: "Select based on Dashboard module. (View as Admin)", class: "mx-5" },
      { id: "m10-4", label: "1.1.2", actionName: "Main>Dashboard>Employee", details: "Select based on Dashboard module. (View as Employee/Client)", class: "mx-5" },
      { id: "m10-5", label: "1.1.3", actionName: "Main>Dashboard>Applicant", details: "Select based on Dashboard module. (View as Applicant)", class: "mx-5 mb-4" },

      { id: "m10-6", label: "2", actionName: "Employees" },

      { id: "m10-7", label: "2.1", actionName: "Employees>Employees", class: "mx-3" },

      { id: "m10-8", label: "2.1.1", actionName: "Employees>Employees>My Profile", class: "mx-5" },
      { id: "m10-9", label: "2.1.2", actionName: "Employees>Employees>My Leaves", class: "mx-5" },
      { id: "m10-10", label: "2.1.3", actionName: "Employees>Employees>All Employee", class: "mx-5" },
      { id: "m10-11", label: "2.1.4", actionName: "Employees>Employees>All Applicant", class: "mx-5" },
      { id: "m10-12", label: "2.1.5", actionName: "Employees>Employees>Manage Leaves", class: "mx-5" },
      { id: "m10-13", label: "2.1.6", actionName: "Employees>Employees>Manage Trainings", class: "mx-5" },
      { id: "m10-14", label: "2.1.7", actionName: "Employees>Employees>Manage Attendance", class: "mx-5 mb-4" },

      { id: "m10-15", label: "2.2", actionName: "Employees>Downloadable", class: "mx-3 mb-4" },

      { id: "m10-16", label: "3", actionName: "HR" },

      { id: "m10-17", label: "3.1", actionName: "HR>Clients", class: "mx-3" },

      { id: "m10-18", label: "3.1.1", actionName: "HR>Clients>Manage Clients", class: "mx-5" },
      { id: "m10-19", label: "3.1.2", actionName: "HR>Clients>Manage Detachments", class: "mx-5 mb-4" },

      { id: "m10-20", label: "3.2", actionName: "HR>Payroll", class: "mx-3" },

      { id: "m10-21", label: "3.2.1", actionName: "HR>Payroll>My Payslip", class: "mx-5" },
      { id: "m10-22", label: "3.2.2", actionName: "HR>Payroll>Manage Employee Salary", class: "mx-5" },
      { id: "m10-23", label: "3.2.3", actionName: "HR>Payroll>Manage SSS", class: "mx-5" },
      { id: "m10-24", label: "3.2.4", actionName: "HR>Payroll>Manage PAGIBIG", class: "mx-5" },
      { id: "m10-25", label: "3.2.5", actionName: "HR>Payroll>Manage PhilHealth", class: "mx-5" },
      { id: "m10-26", label: "3.2.6", actionName: "HR>Payroll>Manage Deductions", class: "mx-5" },
      { id: "m10-27", label: "3.2.7", actionName: "HR>Payroll>Payroll Reports", class: "mx-5 mb-4" },

      { id: "m10-28", label: "4", actionName: "Admin" },

      { id: "m10-29", label: "4.1", actionName: "Admin>Assets", class: "mx-3" },

      { id: "m10-30", label: "4.1.1", actionName: "Admin>Assets>My Purchase Request", class: "mx-5" },
      { id: "m10-31", label: "4.1.2", actionName: "Admin>Assets>Manage Item Codes", class: "mx-5" },
      { id: "m10-32", label: "4.1.3", actionName: "Admin>Assets>Manage Item Categories", class: "mx-5" },
      { id: "m10-33", label: "4.1.4", actionName: "Admin>Assets>Manage Purchase Requests", class: "mx-5" },
      { id: "m10-34", label: "4.1.5", actionName: "Admin>Assets>Manage Suppliers", class: "mx-5" },
      { id: "m10-35", label: "4.1.6", actionName: "Admin>Assets>Manage Assets", class: "mx-5 mb-4" },

      { id: "m10-36", label: "4.2", actionName: "Admin>Administration", class: "mx-3" },

      { id: "m10-37", label: "4.2.1", actionName: "Admin>Administration>Manage Vacancy Source", class: "mx-5" },
      { id: "m10-38", label: "4.2.2", actionName: "Admin>Administration>Manage Departments", class: "mx-5" },
      { id: "m10-39", label: "4.2.3", actionName: "Admin>Administration>Manage Designations", class: "mx-5" },
      { id: "m10-40", label: "4.2.4", actionName: "Admin>Administration>Manage Holidays", class: "mx-5" },
      { id: "m10-41", label: "4.2.5", actionName: "Admin>Administration>Manage Infraction Templates", class: "mx-5" },
      { id: "m10-42", label: "4.2.6", actionName: "Admin>Administration>Manage Violation Codes", class: "mx-5" },
      { id: "m10-43", label: "4.2.7", actionName: "Admin>Administration>Manage Leave Settings", class: "mx-5" },
      { id: "m10-44", label: "4.2.8", actionName: "Admin>Administration>Audit Logs", class: "mx-5 mb-4" },

      { id: "m10-45", label: "5", actionName: "Organization" },

      { id: "m10-46", label: "5.1", actionName: "Organization>Organization Chart", class: "mx-3 mb-4" },
    ]
  },
];

export const actionIdList = {
  "option-to-view-own-profile": "m1-1",
  "option-to-view-change-password": "m1-2",
  "option-to-view-about": "m1-3",
  "view-as-admin-dashboard": "m2-1",
  "view-as-client-dashboard": "m2-2",
  "view-as-employee-dashboard": "m2-3",
  "view-as-applicant-dashboard": "m2-4",
  "view-header": "m2-5",
  "view-cards": "m2-6",
  "view-upcoming-holidays": "m2-7",
  "view-performance": "m2-8",
  "view-own-profile": "m3-1",
  "update-own-profile": "m3-2",
  "view-profile-tab": "m3-3",
  "update-in-profile-tab": "m3-4",
  "view-projects-tab": "m3-5",
  "update-in-projects-tab": "m3-6",
  "view-bank-and-statutory-tab": "m3-7",
  "update-in-bank-and-statutory-tab": "m3-8",
  "view-deduction-and-allowance-tab": "m3-9",
  "update-in-deduction-and-allowance-tab": "m3-10",
  "view-assets-tab": "m3-11",
  "update-in-assets-tab": "m3-12",
  "view-DTR-tab": "m3-13",
  "update-in-DTR-tab": "m3-14",
  "view-infraction-tab": "m3-15",
  "update-in-infraction-tab": "m3-16",
  "view-awards-tab": "m3-17",
  "update-in-awards-tab": "m3-18",
  "view-training-tab": "m3-19",
  "update-in-training-tab": "m3-20",
  "view-performance-tab": "m3-21",
  "view-hiring-process-tab": "m3-22",
  "update-hiring-process-tab": "m3-23",
  "view-own-leave": "m3-24",
  "update-own-leave": "m3-25",
  "delete-own-leave": "m3-26",
  "view-employee-list": "m3-27",
  "view-other-employee-profile": "m3-28",
  "update-other-employee-profile": "m3-29",
  "delete-other-employee-profile": "m3-30",
  "view-applicants": "m3-31",
  "view-applicant-profile": "m3-32",
  "update-applicant-profile": "m3-33",
  "delete-applicant-profile": "m3-34",
  "view-leave-requests": "m3-35",
  "update-leave-request": "m3-36",
  "view-training-list": "m3-37",
  "add-training-item": "m3-38",
  "view-training-logs": "m3-39",
  "view-training-certification": "m3-40",
  "update-training-certification": "m3-41",
  "view-training-schedules": "m3-42",
  "view-training-schedule-logs": "m3-43",
  "update-training-item": "m3-44",
  "delete-training-item": "m3-45",
  "add-training-schedule": "m3-46",
  "update-training-schedule": "m3-47",
  "cancel-training-schedule": "m3-48",
  "delete-training-schedule": "m3-49",
  "view-training-paticipants": "m3-50",
  "accept-training-paticipant": "m3-51",
  "decline-training-paticipant": "m3-52",
  "view-employee-attendace": "m3-53",
  "update-employee-attendace": "m3-54",
  "update-in-employee-profile-to-be-admin-payroll": "m3-55",
  "view-downloadable-list": "m4-1",
  "add-downloadables": "m4-2",
  "delete-downloadables": "m4-3",
  "view-client-list": "m5-1",
  "view-client-profile": "m5-2",
  "add-clients": "m5-3",
  "update-clients": "m5-4",
  "delete-clients": "m5-5",
  "view-detachment-list": "m5-6",
  "add-detachment": "m5-7",
  "update-detachment": "m5-8",
  "delete-detachment": "m5-9",
  "view-and-edit-admin-rate": "m5-10",
  "view-and-edit-managerial-rate": "m5-11",
  "approve-DTR": "m5-12",
  "disapprove-DTR": "m5-13",
  "view-own-payslip": "m6-1",
  "view-other-payslip": "m6-2",
  "view-built-payroll": "m6-3",
  "build-payroll": "m6-4",
  "view-sss-table": "m6-5",
  "update-sss-table": "m6-6",
  "view-pagibig-table": "m6-7",
  "update-pagibig-table": "m6-8",
  "view-philhealth-table": "m6-9",
  "update-philhealth-table": "m6-10",
  "view-payroll-reports": "m6-11",
  "view-13th-month-payroll-report": "m6-12",
  "view-cashbond-payroll-report": "m6-13",
  "view-deductions-payroll-report": "m6-14",
  "view-government-contribution-payroll-report": "m6-15",
  "view-loans-payroll-report": "m6-16",
  "view-retirement-pay-payroll-report": "m6-17",
  "add-multiple-deductions": "m6-18",
  "mark-designation-as-admin-type": "m6-19",
  "mark-designation-as-managerial-type": "m6-20",
  "view-and-edit-admin-type": "m6-21",
  "view-and-edit-managerial-type": "m6-22",
  "view-own-purchase-request-list": "m7-1",
  "add-own-purchase-request": "m7-2",
  "update-own-purchase-request": "m7-3",
  "delete-own-purchase-request": "m7-4",
  "view-own-purchase-request": "m7-5",
  "view-and-update-other-purchase-request": "m7-6",
  "view-item-code-list": "m7-7",
  "add-item-codes": "m7-8",
  "update-item-codes": "m7-9",
  "delete-item-codes": "m7-10",
  "view-item-category-list": "m7-11",
  "add-item-categories": "m7-12",
  "update-item-categories": "m7-13",
  "delete-item-categories": "m7-14",
  "view-pending-purchase-request": "m7-15",
  "update-pending-purchase-request": "m7-16",
  "view-approved-purchase-request": "m7-17",
  "update-approved-purchase-request": "m7-18",
  "view-for-receiving-purchase-request": "m7-19",
  "update-for-receiving-purchase-request": "m7-20",
  "view-supplier-list": "m7-21",
  "add-suppliers": "m7-22",
  "update-suppliers": "m7-23",
  "delete-suppliers": "m7-24",
  "view-asset-list": "m7-25",
  "view-asset-details": "m7-26",
  "update-asset-details": "m7-27",
  "view-vacancy-source-list": "m8-1",
  "add-vacancy-source": "m8-2",
  "update-vacancy-source": "m8-3",
  "delete-vacancy-source": "m8-4",
  "view-department-list": "m8-5",
  "add-department": "m8-6",
  "update-department": "m8-7",
  "delete-department": "m8-8",
  "view-designation-list": "m8-9",
  "add-designation": "m8-10",
  "update-designation": "m8-11",
  "delete-designation": "m8-12",
  "view-holiday-list": "m8-13",
  "add-holiday": "m8-14",
  "update-holiday": "m8-15",
  "delete-holiday": "m8-16",
  "view-infraction-template-list": "m8-17",
  "add-infraction-template": "m8-18",
  "update-infraction-template": "m8-19",
  "delete-infraction-template": "m8-20",
  "view-violoation-code-list": "m8-21",
  "add-violoation-code": "m8-22",
  "update-violoation-code": "m8-23",
  "delete-violoation-code": "m8-24",
  "view-violoation-category-list": "m8-25",
  "add-violoation-category": "m8-26",
  "update-violoation-category": "m8-27",
  "delete-violoation-category": "m8-28",
  "view-leave-settings": "m8-29",
  "update-leave-settings": "m8-30",
  "view-audit-logs": "m8-31",
  "view-organization-chart": "m9-1",
  "add-organization-cell": "m9-2",
  "update-organization-cell": "m9-3",
  "delete-organization-cell": "m9-4",
  "Main": "m10-1",
  "Main>Dashboard": "m10-2",
  "Main>Dashboard>Admin": "m10-3",
  "Main>Dashboard>Employee": "m10-4",
  "Main>Dashboard>Applicant": "m10-5",
  "Employees": "m10-6",
  "Employees>Employees": "m10-7",
  "Employees>Employees>My Profile": "m10-8",
  "Employees>Employees>My Leaves": "m10-9",
  "Employees>Employees>All Employee": "m10-10",
  "Employees>Employees>All Applicant": "m10-11",
  "Employees>Employees>Manage Leaves": "m10-12",
  "Employees>Employees>Manage Trainings": "m10-13",
  "Employees>Employees>Manage Attendance": "m10-14",
  "Employees>Downloadable": "m10-15",
  "HR": "m10-16",
  "HR>Clients": "m10-17",
  "HR>Clients>Manage Clients": "m10-18",
  "HR>Clients>Manage Detachments": "m10-19",
  "HR>Payroll": "m10-20",
  "HR>Payroll>My Payslip": "m10-21",
  "HR>Payroll>Manage Employee Salary": "m10-22",
  "HR>Payroll>Manage SSS": "m10-23",
  "HR>Payroll>Manage PAGIBIG": "m10-24",
  "HR>Payroll>Manage PhilHealth": "m10-25",
  "HR>Payroll>Manage Deductions": "m10-26",
  "HR>Payroll>Payroll Reports": "m10-27",
  "Admin": "m10-28",
  "Admin>Assets": "m10-29",
  "Admin>Assets>My Purchase Request": "m10-30",
  "Admin>Assets>Manage Item Codes": "m10-31",
  "Admin>Assets>Manage Item Categories": "m10-32",
  "Admin>Assets>Manage Purchase Requests": "m10-33",
  "Admin>Assets>Manage Suppliers": "m10-34",
  "Admin>Assets>Manage Assets": "m10-35",
  "Admin>Administration": "m10-36",
  "Admin>Administration>Manage Vacancy Source": "m10-37",
  "Admin>Administration>Manage Departments": "m10-38",
  "Admin>Administration>Manage Designations": "m10-39",
  "Admin>Administration>Manage Holidays": "m10-40",
  "Admin>Administration>Manage Infraction Templates": "m10-41",
  "Admin>Administration>Manage Violation Codes": "m10-42",
  "Admin>Administration>Manage Leave Settings": "m10-43",
  "Admin>Administration>Audit Logs": "m10-44",
  "Organization": "m10-45",
  "Organization>Organization Chart": "m10-46",
}

export function userCan(actionId: string, userAccess: DesingationAccessInterface[]): boolean {
  // `Action` Index and `Can` Index
  const [a, c] = actionId.replace("m", "").split("-").map(v => Number(v));
  const retVal = userAccess[a - 1]?.Actions[c - 1];
  return Boolean(retVal);
}