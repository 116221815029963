import { BehaviorSubject } from 'rxjs';

export class routes {
  public static layoutDirection: BehaviorSubject<string> =
    new BehaviorSubject<string>(localStorage.getItem('rtl') || '');

  private static Url = '';

  static rtl = this.layoutDirection.subscribe((res: string) => {
    this.Url = res;
  });


  // FOR HRIS ONLY


  public static get baseUrl(): string {
    return this.Url;
  }
  public static get login(): string {
    return this.baseUrl + '/login';
  }
  public static get loginpro(): string {
    return this.baseUrl + '/login';
  }
  public static get forgot_password(): string {
    return this.baseUrl + '/forgot-password';
  }
  public static get forgotpassword(): string {
    return this.baseUrl + '/forgot-password';
  }




  public static get dashboard(): string {
    return this.baseUrl + '/dashboard';
  }
  public static get admin(): string {
    return this.baseUrl + '/dashboard/admin';
  }
  public static get employeeDashboard(): string {
    return this.baseUrl + '/dashboard/employee';
  }
  public static get adminDashboard(): string {
    return this.baseUrl + '/dashboard/admin';
  }






  public static get employees(): string {
    return this.baseUrl + '/employees';
  }
  public static get manageTraining(): string {
    return this.baseUrl + '/employees/manage-training';
  }
  public static get employee_page(): string {
    return this.baseUrl + '/employees/employee-page';
  }
  public static get holidays(): string {
    return this.baseUrl + '/employees/holidays';
  }
  public static get leaveadmin(): string {
    return this.baseUrl + '/employees/leave-admin';
  }
  public static get leaveemployee(): string {
    return this.baseUrl + '/employees/leave-employee';
  }
  public static get leavesettings(): string {
    return this.baseUrl + '/employees/leave-settings';
  }
  public static get attendanceadmin(): string {
    return this.baseUrl + '/employees/attendance-admin';
  }
  public static get attendanceemployee(): string {
    return this.baseUrl + '/employees/attendance-employee';
  }
  public static get departments(): string {
    return this.baseUrl + '/employees/departments';
  }
  public static get designations(): string {
    return this.baseUrl + '/employees/designations';
  }
  public static get timesheet(): string {
    return this.baseUrl + '/employees/timesheet';
  }
  public static get shiftschedule(): string {
    return this.baseUrl + '/employees/shift-schedule';
  }
  public static get overtime(): string {
    return this.baseUrl + '/employees/overtime';
  }
  public static get downloadable(): string {
    return this.baseUrl + '/employees/downloadable'
  }
  public static get audit_logs(): string {
    return this.baseUrl + '/employees/audit-logs'
  }
  public static get orgChart(): string {
    return this.baseUrl + '/employees/organization-chart'
  }
  public static get training_list(): string {
    return this.baseUrl + '/employees/training-list'
  }
  public static get all_applicant(): string {
    return this.baseUrl + '/employees/all-applicant'
  }
  public static get applicant_profile(): string {
    return this.baseUrl + '/employees/applicant-profile'
  }
  public static get employeeProfile(): string {
    return this.baseUrl + '/employees/employee-profile';
  }
  public static get manageVacancySource(): string {
    return this.baseUrl + '/employees/manage-vacancy-source';
  }
  public static get manageInfractionTemplates(): string {
    return this.baseUrl + '/employees/manage-infraction-templates';
  }
  public static get manageViolationCodes(): string {
    return this.baseUrl + '/employees/manage-violation-codes';
  }


  public static get assets(): string {
    return this.baseUrl + '/assets/assets-main';
  }
  public static get assetsDetails(): string {
    return this.baseUrl + '/assets/assets-details';
  }
  public static get assetSupplier(): string {
    return this.baseUrl + '/assets/assets-supplier';
  }
  public static get manageItemCodes(): string {
    return this.baseUrl + '/assets/manage-item-codes';
  }
  public static get manageItemCategories(): string {
    return this.baseUrl + '/assets/manage-item-categories';
  }



  public static get viewPr(): string {
    return this.baseUrl + '/assets/pr/view';
  }
  public static get prListBy(): string {
    return this.baseUrl + '/assets/pr/by';
  }

  public static get managePrList(): string {
    return this.baseUrl + '/assets/pr/manage/pr-list';
  }
  public static get managePoList(): string {
    return this.baseUrl + '/assets/pr/manage/po-list';
  }
  public static get managePoForReceive(): string {
    return this.baseUrl + '/assets/pr/manage/po-for-receiving-list';
  }



  public static get payroll(): string {
    return this.baseUrl + '/payroll';
  }
  public static get sss(): string {
    return this.baseUrl + '/payroll/sss'
  }
  public static get pagibig(): string {
    return this.baseUrl + '/payroll/pagibig'
  }
  public static get philhealth(): string {
    return this.baseUrl + '/payroll/philhealth'
  }
  public static get withholdtax(): string {
    return this.baseUrl + '/payroll/withhold-tax'
  }
  public static get manageEmployeesalary(): string {
    return this.baseUrl + '/payroll/manage-employee-salary';
  }
  public static get employeeSalaryView(): string {
    return this.baseUrl + '/payroll/employee-salary';
  }
  public static get manageDeduction(): string {
    return this.baseUrl + '/payroll/manage-deduction';
  }
  public static get payrollForApprovals(): string {
    return this.baseUrl + '/payroll/for-approvals';
  }

  
  public static get payrollReport(): string {
    return this.baseUrl + '/payroll/payroll-reports';
  }




  public static get detachments(): string {
    return this.baseUrl + '/clients/detachments';
  }
  public static get clientPage(): string {
    return this.baseUrl + '/clients/client-page';
  }
  public static get clientProfile(): string {
    return this.baseUrl + '/clients/client-profile';
  }






  public static get error404(): string {
    return this.baseUrl + '/404';
  }
  public static get error500(): string {
    return this.baseUrl + '/500';
  }
  public static get error(): string {
    return this.baseUrl + '/404';
  }
  public static get errors(): string {
    return this.baseUrl + '/500';
  }

}
