import { DesingationAccessInterface } from "src/app/shared/interface/global-interface";
import { Helper } from "./Helper";

export interface UserAccessInterface {
  id: string;
  moduleName: string;
  actions: { id: string, actionName: string, class?: string }[];
}

export const HRISACM: UserAccessInterface[] = [
  {
    id: "m1",
    moduleName: "website-header",
    actions: [
      { id: "m1-1", actionName: "option-to-view-own-profile" },
      { id: "m1-2", actionName: "option-to-view-change-password" },
      { id: "m1-3", actionName: "option-to-view-about" },
    ]
  },
  {
    id: "m2",
    moduleName: "dashboard-module",
    actions: [
      { id: "m2-1", actionName: "view-as-admin-dashboard" },
      { id: "m2-2", actionName: "view-as-client-dashboard" },
      { id: "m2-3", actionName: "view-as-employee-dashboard" },
      { id: "m2-4", actionName: "view-as-applicant-dashboard", class: "mb-4" },

      { id: "m2-5", actionName: "view-header" },
      { id: "m2-6", actionName: "view-cards" },
      { id: "m2-7", actionName: "view-upcoming-holidays" },
      { id: "m2-8", actionName: "view-performance" },
    ]
  },
  {
    id: "m3",
    moduleName: "employee-module",
    actions: [
      { id: "m3-1", actionName: "view-own-profile" },
      { id: "m3-2", actionName: "update-own-profile", class: "mb-4" },

      { id: "m3-3", actionName: "view-profile-tab" },
      { id: "m3-4", actionName: "update-in-profile-tab" },
      { id: "m3-5", actionName: "view-projects-tab" },
      { id: "m3-6", actionName: "update-in-projects-tab" },
      { id: "m3-7", actionName: "view-bank-and-statutory-tab" },
      { id: "m3-8", actionName: "update-in-bank-and-statutory-tab" },
      { id: "m3-9", actionName: "view-deduction-and-allowance-tab" },
      { id: "m3-10", actionName: "update-in-deduction-and-allowance-tab" },
      { id: "m3-11", actionName: "view-assets-tab" },
      { id: "m3-12", actionName: "update-in-assets-tab" },
      { id: "m3-13", actionName: "view-DTR-tab" },
      { id: "m3-14", actionName: "update-in-DTR-tab" },
      { id: "m3-15", actionName: "view-infraction-tab" },
      { id: "m3-16", actionName: "update-in-infraction-tab" },
      { id: "m3-17", actionName: "view-awards-tab" },
      { id: "m3-18", actionName: "update-in-awards-tab" },
      { id: "m3-19", actionName: "view-training-tab" },
      { id: "m3-20", actionName: "update-in-training-tab" },
      { id: "m3-21", actionName: "view-performance-tab" },
      { id: "m3-22", actionName: "view-hiring-process-tab", class: "mb-4" },
      { id: "m3-23", actionName: "update-hiring-process-tab", class: "mb-4" },

      { id: "m3-24", actionName: "view-own-leave" },
      { id: "m3-25", actionName: "update-own-leave" },
      { id: "m3-26", actionName: "delete-own-leave", class: "mb-4" },

      { id: "m3-27", actionName: "view-employee-list" },
      { id: "m3-28", actionName: "view-employee-profile" },
      { id: "m3-29", actionName: "update-employee-profile" },
      { id: "m3-30", actionName: "delete-employee-profile", class: "mb-4" },

      { id: "m3-31", actionName: "view-applicants" },
      { id: "m3-32", actionName: "view-applicant-profile" },
      { id: "m3-33", actionName: "update-applicant-profile" },
      { id: "m3-34", actionName: "delete-applicant-profile", class: "mb-4" },

      { id: "m3-35", actionName: "view-leave-requests" },
      { id: "m3-36", actionName: "update-leave-request", class: "mb-4" },

      { id: "m3-37", actionName: "view-training-list" },
      { id: "m3-38", actionName: "add-training-item", class: "mb-4" },

      { id: "m3-39", actionName: "view-training-logs" },
      { id: "m3-40", actionName: "view-training-certification" },
      { id: "m3-41", actionName: "update-training-certification", class: "mb-4" },

      { id: "m3-42", actionName: "view-training-schedules" },
      { id: "m3-43", actionName: "view-training-schedule-logs" },
      { id: "m3-44", actionName: "update-training-item" },
      { id: "m3-45", actionName: "delete-training-item", class: "mb-4" },

      { id: "m3-46", actionName: "add-training-schedule" },
      { id: "m3-47", actionName: "update-training-schedule" },
      { id: "m3-48", actionName: "cancel-training-schedule" },
      { id: "m3-49", actionName: "delete-training-schedule", class: "mb-4" },

      { id: "m3-50", actionName: "view-training-paticipants" },
      { id: "m3-51", actionName: "accept-training-paticipant" },
      { id: "m3-52", actionName: "decline-training-paticipant", class: "mb-4" },

      { id: "m3-53", actionName: "view-employee-attendace" },
      { id: "m3-54", actionName: "update-employee-attendace" },
    ]
  },
  {
    id: "m4",
    moduleName: "downloadable-module",
    actions: [
      { id: "m4-1", actionName: "view-downloadable-list" },
      { id: "m4-2", actionName: "add-downloadables" },
      { id: "m4-3", actionName: "delete-downloadables" },
    ]
  },
  {
    id: "m5",
    moduleName: "clients-module",
    actions: [
      { id: "m5-1", actionName: "view-client-list" },
      { id: "m5-2", actionName: "view-client-profile" },
      { id: "m5-3", actionName: "add-clients" },
      { id: "m5-4", actionName: "update-clients" },
      { id: "m5-5", actionName: "delete-clients", class: "mb-4" },

      { id: "m5-6", actionName: "view-detachment-list" },
      { id: "m5-7", actionName: "add-detachment" },
      { id: "m5-8", actionName: "update-detachment" },
      { id: "m5-9", actionName: "delete-detachment", class: "mb-4" },

      { id: "m5-10", actionName: "view-and-edit-admin-rate" },
      { id: "m5-11", actionName: "view-and-edit-manager-rate" },
    ]
  },
  {
    id: "m6",
    moduleName: "payroll-module",
    actions: [
      { id: "m6-1", actionName: "view-own-payslip" },
      { id: "m6-2", actionName: "view-other-payslip" },
      { id: "m6-3", actionName: "view-built-payroll" },
      { id: "m6-4", actionName: "build-payroll", class: "mb-4" },

      { id: "m6-5", actionName: "view-sss-table" },
      { id: "m6-6", actionName: "update-sss-table", class: "mb-4" },

      { id: "m6-7", actionName: "view-pagibig-table" },
      { id: "m6-8", actionName: "update-pagibig-table", class: "mb-4" },

      { id: "m6-9", actionName: "view-philhealth-table" },
      { id: "m6-10", actionName: "update-philhealth-table", class: "mb-4" },

      { id: "m6-11", actionName: "view-contribution-list", class: "mb-4" },

      { id: "m6-12", actionName: "add-multiple-deductions" },
    ]
  },
  {
    id: "m7",
    moduleName: "assets-module",
    actions: [
      { id: "m7-1", actionName: "view-own-purchase-request-list" },
      { id: "m7-2", actionName: "add-own-purchase-request" },
      { id: "m7-3", actionName: "update-own-purchase-request" },
      { id: "m7-4", actionName: "delete-own-purchase-request", class: "mb-4" },

      { id: "m7-5", actionName: "view-own-purchase-request" },
      { id: "m7-6", actionName: "view-other-purchase-request", class: "mb-4" },

      { id: "m7-7", actionName: "view-item-code-list" },
      { id: "m7-8", actionName: "add-item-codes" },
      { id: "m7-9", actionName: "update-item-codes" },
      { id: "m7-10", actionName: "delete-item-codes", class: "mb-4" },

      { id: "m7-11", actionName: "view-item-category-list" },
      { id: "m7-12", actionName: "add-item-categories" },
      { id: "m7-13", actionName: "update-item-categories" },
      { id: "m7-14", actionName: "delete-item-categories", class: "mb-4" },

      { id: "m7-15", actionName: "view-pending-purchase-request" },
      { id: "m7-16", actionName: "update-pending-purchase-request" },
      { id: "m7-17", actionName: "view-approved-purchase-request" },
      { id: "m7-18", actionName: "update-approved-purchase-request" },
      { id: "m7-19", actionName: "view-for-receiving-purchase-request" },
      { id: "m7-20", actionName: "update-for-receiving-purchase-request", class: "mb-4" },

      { id: "m7-21", actionName: "view-supplier-list" },
      { id: "m7-22", actionName: "add-suppliers" },
      { id: "m7-23", actionName: "update-suppliers" },
      { id: "m7-24", actionName: "delete-suppliers", class: "mb-4" },

      { id: "m7-25", actionName: "view-asset-list" },
      { id: "m7-26", actionName: "view-asset-details" },
      { id: "m7-27", actionName: "update-asset-details", class: "mb-4" },
    ]
  },
  {
    id: "m8",
    moduleName: "administration-module",
    actions: [
      { id: "m8-1", actionName: "view-vacancy-source-list" },
      { id: "m8-2", actionName: "add-vacancy-source" },
      { id: "m8-3", actionName: "update-vacancy-source" },
      { id: "m8-4", actionName: "delete-vacancy-source", class: "mb-4" },

      { id: "m8-5", actionName: "view-department-list" },
      { id: "m8-6", actionName: "add-department" },
      { id: "m8-7", actionName: "update-department" },
      { id: "m8-8", actionName: "delete-department", class: "mb-4" },

      { id: "m8-9", actionName: "view-designation-list" },
      { id: "m8-10", actionName: "add-designation" },
      { id: "m8-11", actionName: "update-designation" },
      { id: "m8-12", actionName: "delete-designation", class: "mb-4" },

      { id: "m8-13", actionName: "view-holiday-list" },
      { id: "m8-14", actionName: "add-holiday" },
      { id: "m8-15", actionName: "update-holiday" },
      { id: "m8-16", actionName: "delete-holiday", class: "mb-4" },

      { id: "m8-17", actionName: "view-infraction-template-list" },
      { id: "m8-18", actionName: "add-infraction-template" },
      { id: "m8-19", actionName: "update-infraction-template" },
      { id: "m8-20", actionName: "delete-infraction-template", class: "mb-4" },

      { id: "m8-21", actionName: "view-leave-settings" },
      { id: "m8-22", actionName: "update-leave-settings", class: "mb-4" },

      { id: "m8-23", actionName: "view-audit-logs" },
    ]
  },
  {
    id: "m9",
    moduleName: "organization-chart-module",
    actions: [
      { id: "m9-1", actionName: "view-organization-chart" },
      { id: "m9-2", actionName: "add-organization-cell" },
      { id: "m9-3", actionName: "update-organization-cell" },
      { id: "m9-4", actionName: "delete-organization-cell" },
    ]
  },
  {
    id: "m10",
    moduleName: "menu-bar-module",
    actions: [
      { id: "m10-1", actionName: "Main" },
      { id: "m10-2", actionName: "Main>Dashboard" },
      { id: "m10-3", actionName: "Main>Dashboard>Admin" },
      { id: "m10-4", actionName: "Main>Dashboard>Employee" },
      { id: "m10-5", actionName: "Main>Dashboard>Applicant", class: "mb-4" },

      { id: "m10-6", actionName: "Employees" },
      { id: "m10-7", actionName: "Employees>Employees" },
      { id: "m10-8", actionName: "Employees>Employees>My Profile" },
      { id: "m10-9", actionName: "Employees>Employees>My Leaves" },
      { id: "m10-10", actionName: "Employees>Employees>All Employee" },
      { id: "m10-11", actionName: "Employees>Employees>All Applicant" },
      { id: "m10-12", actionName: "Employees>Employees>Manage Leaves" },
      { id: "m10-13", actionName: "Employees>Employees>Manage Trainings" },
      { id: "m10-14", actionName: "Employees>Employees>Manage Attendance", class: "mb-4" },

      { id: "m10-15", actionName: "Employees>Downloadable", class: "mb-4" },

      { id: "m10-16", actionName: "HR" },
      { id: "m10-17", actionName: "HR>Clients" },
      { id: "m10-18", actionName: "HR>Clients>Manage Clients" },
      { id: "m10-19", actionName: "HR>Clients>Manage Detachments", class: "mb-4" },

      { id: "m10-20", actionName: "HR>Payroll" },
      { id: "m10-21", actionName: "HR>Payroll>My Payslip" },
      { id: "m10-22", actionName: "HR>Payroll>Manage Employee Salary" },
      { id: "m10-23", actionName: "HR>Payroll>Manage SSS" },
      { id: "m10-24", actionName: "HR>Payroll>Manage PAGIBIG" },
      { id: "m10-25", actionName: "HR>Payroll>Manage PhilHealth" },
      { id: "m10-26", actionName: "HR>Payroll>Manage Deductions" },
      { id: "m10-27", actionName: "HR>Payroll>Contribution Report", class: "mb-4" },

      { id: "m10-28", actionName: "Admin" },
      { id: "m10-29", actionName: "Admin>Assets" },
      { id: "m10-30", actionName: "Admin>Assets>My Purchase Request" },
      { id: "m10-31", actionName: "Admin>Assets>Manage Item Codes" },
      { id: "m10-32", actionName: "Admin>Assets>Manage Item Categories" },
      { id: "m10-33", actionName: "Admin>Assets>Manage Purchase Requests" },
      { id: "m10-34", actionName: "Admin>Assets>Manage Suppliers" },
      { id: "m10-35", actionName: "Admin>Assets>Manage Assets", class: "mb-4" },

      { id: "m10-36", actionName: "Admin>Administration" },
      { id: "m10-37", actionName: "Admin>Administration>Manage Vacancy Source" },
      { id: "m10-38", actionName: "Admin>Administration>Manage Departments" },
      { id: "m10-39", actionName: "Admin>Administration>Manage Designations" },
      { id: "m10-40", actionName: "Admin>Administration>Manage Holidays" },
      { id: "m10-41", actionName: "Admin>Administration>Manage Infraction Templates" },
      { id: "m10-42", actionName: "Admin>Administration>Manage Leave Settings" },
      { id: "m10-43", actionName: "Admin>Administration>Audit Logs", class: "mb-4" },

      { id: "m10-44", actionName: "Organization" },
      { id: "m10-45", actionName: "Organization>Organization Chart" },
    ]
  },
];

export const actionIdList = {
  "option-to-view-own-profile": "m1-1",
  "option-to-view-change-password": "m1-2",
  "option-to-view-about": "m1-3",
  "view-as-admin-dashboard": "m2-1",
  "view-as-client-dashboard": "m2-2",
  "view-as-employee-dashboard": "m2-3",
  "view-as-applicant-dashboard": "m2-4",
  "view-header": "m2-5",
  "view-cards": "m2-6",
  "view-upcoming-holidays": "m2-7",
  "view-performance": "m2-8",
  "view-own-profile": "m3-1",
  "update-own-profile": "m3-2",
  "view-profile-tab": "m3-3",
  "update-in-profile-tab": "m3-4",
  "view-projects-tab": "m3-5",
  "update-in-projects-tab": "m3-6",
  "view-bank-and-statutory-tab": "m3-7",
  "update-in-bank-and-statutory-tab": "m3-8",
  "view-deduction-and-allowance-tab": "m3-9",
  "update-in-deduction-and-allowance-tab": "m3-10",
  "view-assets-tab": "m3-11",
  "update-in-assets-tab": "m3-12",
  "view-DTR-tab": "m3-13",
  "update-in-DTR-tab": "m3-14",
  "view-infraction-tab": "m3-15",
  "update-in-infraction-tab": "m3-16",
  "view-awards-tab": "m3-17",
  "update-in-awards-tab": "m3-18",
  "view-training-tab": "m3-19",
  "update-in-training-tab": "m3-20",
  "view-performance-tab": "m3-21",
  "view-hiring-process-tab": "m3-22",
  "update-hiring-process-tab": "m3-23",
  "view-own-leave": "m3-24",
  "update-own-leave": "m3-25",
  "delete-own-leave": "m3-26",
  "view-employee-list": "m3-27",
  "view-employee-profile": "m3-28",
  "update-employee-profile": "m3-29",
  "delete-employee-profile": "m3-30",
  "view-applicants": "m3-31",
  "view-applicant-profile": "m3-32",
  "update-applicant-profile": "m3-33",
  "delete-applicant-profile": "m3-34",
  "view-leave-requests": "m3-35",
  "update-leave-request": "m3-36",
  "view-training-list": "m3-37",
  "add-training-item": "m3-38",
  "view-training-logs": "m3-39",
  "view-training-certification": "m3-40",
  "update-training-certification": "m3-41",
  "view-training-schedules": "m3-42",
  "view-training-schedule-logs": "m3-43",
  "update-training-item": "m3-44",
  "delete-training-item": "m3-45",
  "add-training-schedule": "m3-46",
  "update-training-schedule": "m3-47",
  "cancel-training-schedule": "m3-48",
  "delete-training-schedule": "m3-49",
  "view-training-paticipants": "m3-50",
  "accept-training-paticipant": "m3-51",
  "decline-training-paticipant": "m3-52",
  "view-employee-attendace": "m3-53",
  "update-employee-attendace": "m3-54",
  "view-downloadable-list": "m4-1",
  "add-downloadables": "m4-2",
  "delete-downloadables": "m4-3",
  "view-client-list": "m5-1",
  "view-client-profile": "m5-2",
  "add-clients": "m5-3",
  "update-clients": "m5-4",
  "delete-clients": "m5-5",
  "view-detachment-list": "m5-6",
  "add-detachment": "m5-7",
  "update-detachment": "m5-8",
  "delete-detachment": "m5-9",
  "view-and-edit-admin-rate": "m5-10",
  "view-and-edit-manager-rate": "m5-11",
  "view-own-payslip": "m6-1",
  "view-other-payslip": "m6-2",
  "view-built-payroll": "m6-3",
  "build-payroll": "m6-4",
  "view-sss-table": "m6-5",
  "update-sss-table": "m6-6",
  "view-pagibig-table": "m6-7",
  "update-pagibig-table": "m6-8",
  "view-philhealth-table": "m6-9",
  "update-philhealth-table": "m6-10",
  "view-contribution-list": "m6-11",
  "add-multiple-deductions": "m6-12",
  "view-own-purchase-request-list": "m7-1",
  "add-own-purchase-request": "m7-2",
  "update-own-purchase-request": "m7-3",
  "delete-own-purchase-request": "m7-4",
  "view-own-purchase-request": "m7-5",
  "view-other-purchase-request": "m7-6",
  "view-item-code-list": "m7-7",
  "add-item-codes": "m7-8",
  "update-item-codes": "m7-9",
  "delete-item-codes": "m7-10",
  "view-item-category-list": "m7-11",
  "add-item-categories": "m7-12",
  "update-item-categories": "m7-13",
  "delete-item-categories": "m7-14",
  "view-pending-purchase-request": "m7-15",
  "update-pending-purchase-request": "m7-16",
  "view-approved-purchase-request": "m7-17",
  "update-approved-purchase-request": "m7-18",
  "view-for-receiving-purchase-request": "m7-19",
  "update-for-receiving-purchase-request": "m7-20",
  "view-supplier-list": "m7-21",
  "add-suppliers": "m7-22",
  "update-suppliers": "m7-23",
  "delete-suppliers": "m7-24",
  "view-asset-list": "m7-25",
  "view-asset-details": "m7-26",
  "update-asset-details": "m7-27",
  "view-vacancy-source-list": "m8-1",
  "add-vacancy-source": "m8-2",
  "update-vacancy-source": "m8-3",
  "delete-vacancy-source": "m8-4",
  "view-department-list": "m8-5",
  "add-department": "m8-6",
  "update-department": "m8-7",
  "delete-department": "m8-8",
  "view-designation-list": "m8-9",
  "add-designation": "m8-10",
  "update-designation": "m8-11",
  "delete-designation": "m8-12",
  "view-holiday-list": "m8-13",
  "add-holiday": "m8-14",
  "update-holiday": "m8-15",
  "delete-holiday": "m8-16",
  "view-infraction-template-list": "m8-17",
  "add-infraction-template": "m8-18",
  "update-infraction-template": "m8-19",
  "delete-infraction-template": "m8-20",
  "view-leave-settings": "m8-21",
  "update-leave-settings": "m8-22",
  "view-audit-logs": "m8-23",
  "view-organization-chart": "m9-1",
  "add-organization-cell": "m9-2",
  "update-organization-cell": "m9-3",
  "delete-organization-cell": "m9-4",
  "Main": "m10-1",
  "Main>Dashboard": "m10-2",
  "Main>Dashboard>Admin": "m10-3",
  "Main>Dashboard>Employee": "m10-4",
  "Main>Dashboard>Applicant": "m10-5",
  "Employees": "m10-6",
  "Employees>Employees": "m10-7",
  "Employees>Employees>My Profile": "m10-8",
  "Employees>Employees>My Leaves": "m10-9",
  "Employees>Employees>All Employee": "m10-10",
  "Employees>Employees>All Applicant": "m10-11",
  "Employees>Employees>Manage Leaves": "m10-12",
  "Employees>Employees>Manage Trainings": "m10-13",
  "Employees>Employees>Manage Attendance": "m10-14",
  "Employees>Downloadable": "m10-15",
  "HR": "m10-16",
  "HR>Clients": "m10-17",
  "HR>Clients>Manage Clients": "m10-18",
  "HR>Clients>Manage Detachments": "m10-19",
  "HR>Payroll": "m10-20",
  "HR>Payroll>My Payslip": "m10-21",
  "HR>Payroll>Manage Employee Salary": "m10-22",
  "HR>Payroll>Manage SSS": "m10-23",
  "HR>Payroll>Manage PAGIBIG": "m10-24",
  "HR>Payroll>Manage PhilHealth": "m10-25",
  "HR>Payroll>Manage Deductions": "m10-26",
  "HR>Payroll>Contribution Report": "m10-27",
  "Admin": "m10-28",
  "Admin>Assets": "m10-29",
  "Admin>Assets>My Purchase Request": "m10-30",
  "Admin>Assets>Manage Item Codes": "m10-31",
  "Admin>Assets>Manage Item Categories": "m10-32",
  "Admin>Assets>Manage Purchase Requests": "m10-33",
  "Admin>Assets>Manage Suppliers": "m10-34",
  "Admin>Assets>Manage Assets": "m10-35",
  "Admin>Administration": "m10-36",
  "Admin>Administration>Manage Vacancy Source": "m10-37",
  "Admin>Administration>Manage Departments": "m10-38",
  "Admin>Administration>Manage Designations": "m10-39",
  "Admin>Administration>Manage Holidays": "m10-40",
  "Admin>Administration>Manage Infraction Templates": "m10-41",
  "Admin>Administration>Manage Leave Settings": "m10-42",
  "Admin>Administration>Audit Logs": "m10-43",
  "Organization": "m10-44",
  "Organization>Organization Chart": "m10-45",
}

export function userCan(actionId: string, userAccess: DesingationAccessInterface[]): boolean {
  // `Action` Index and `Can` Index
  const [a, c] = actionId.replace("m", "").split("-").map(v => Number(v));
  const retVal = userAccess[a - 1]?.Actions[c - 1];
  return Boolean(retVal);
}