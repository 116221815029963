import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';


export class HttpProviderService {

  constructor(private http: HttpClient, private providerPath: string) { }

  sendGetRequest(path: string, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      params: params,
      observe: "response" as 'body',
    };
    return this.http.get(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPostRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.post(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPatchRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.patch(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPutRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.put(`${this.providerPath}${path}`, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendDeleteRequest(path: string, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.delete(`${this.providerPath}${path}`, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

  sendPutRequest_Raw(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.put(path, body, requestOptions).pipe(
      catchError(err => {
        alert(`Server error occurred. Try again later`);
        throw err;
      })
    );
  }

}

//provider service pattern

@Injectable({
  providedIn: 'root'
})
export class MemberProviderService extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, "");
  }
}


//add endpoints here
//can be multiple(microservice pattern)
@Injectable({
  providedIn: 'root'
})
export class HHTProviderService extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL);
  }
}