import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SideBar, SubMenu, routes, } from '../../core.index';
import { Helper } from '../../helpers/methods/Helper';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  allAppliedCandidates!: Array<object>;

  constructor() { }

  public sideBar: SideBar[] = [
    { // Main
      tittle: 'Main',
      icon: 'airplay',
      showAsTab: true,
      separateRoute: false,
      menu: [
        { // Dashboard
          menuValue: 'Dashboard',
          route: routes.dashboard,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'dashboard',
          base: 'dashboard',
          materialicons: 'home',
          subMenus: [
            { // admin
              menuValue: 'Admin Dashboard',
              route: routes.admin,
              base: 'admin',
            },
            { // employee
              menuValue: 'Employee Dashboard',
              route: routes.employeeDashboard,
              base: 'employee',
            },
            { // applicant
              menuValue: 'Applicant Profile',
              route: `${routes.applicant_profile}/${Helper.UserLoginData().ID}`,
              base: 'applicant',
            },
          ],
        }
      ],
    },
    { // Employees
      tittle: 'Employees',
      icon: 'layers',
      showAsTab: false,
      separateRoute: false,
      menu: [
        { // Employees
          menuValue: 'Employees',
          route: routes.employees,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'user',
          base: 'employees',
          // dot: true,
          materialicons: 'people',
          subMenus: [
            { // my-profile-profile
              menuValue: 'My Profile',
              route: `${routes.employeeProfile}/${Helper.UserLoginData().ID}`,
              base: 'my-profile',
            },
            { // leave-employee
              menuValue: 'My Leaves',
              route: routes.leaveemployee,
              base: 'leave-employee',
            },
            { // employee-list
              menuValue: 'All Employees',
              route: routes.employee_page,
              base: 'employee-page',
            },
            { // All Applicants
              menuValue: 'All Applicant',
              route: routes.all_applicant,
              base: 'all_applicant',
            },
            { // leave-admin
              menuValue: 'Manage Leaves',
              route: routes.leaveadmin,
              base: 'leave-admin',
              // currentActive: true,
            },
            { // trainings
              menuValue: 'Manage Trainings',
              route: routes.manageTraining,
              base: 'manage-training'
            },
            { // attendance-admin
              menuValue: 'Manage Attendance',
              route: routes.attendanceadmin,
              base: 'attendance-admin',
            },
          ],
        },
        { // Downloadable
          menuValue: 'Downloadable',
          route: routes.downloadable,
          hasSubRoute: false,
          showSubRoute: false,
          icon: 'download',
          base: 'Downloadable',
          materialicons: 'download',
          subMenus: [],
        },
      ],
    },
    { // HR
      tittle: 'HR',
      icon: 'file',
      showAsTab: false,
      separateRoute: false,
      menu: [
        { // Clients
          menuValue: 'Clients',
          route: routes.clientPage,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'users',
          base: 'clients',
          materialicons: 'person',
          subMenus: [
            { // my-profile-profile
              menuValue: 'Manage Clients',
              route: routes.clientPage,
              base: 'all-clients',
            },
            { // my-profile-profile
              menuValue: 'Manage Detachments',
              route: routes.detachments,
              base: 'detachments',
            },
          ],
        },
        { // Payroll
          menuValue: 'Payroll',
          route: routes.payroll,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'money',
          base: 'payroll',
          materialicons: 'request_quote',
          subMenus: [
            { // salary-view
              menuValue: 'My Payslip',
              route: `${routes.employeeSalaryView}/${Helper.UserLoginData().ID}`,
              base: 'salary-view',
            },
            { // Manage Employee Salary
              menuValue: 'Manage Employee Salary',
              route: routes.manageEmployeesalary,
              base: 'employee-salary',
            },
            { // payroll-items
              menuValue: 'Payroll Items',
              route: routes.payrollitems,
              base: 'payroll-items',
            },
            { // sss
              menuValue: 'Manage SSS',
              route: routes.sss,
              base: 'sss',
            },
            { // pagibig
              menuValue: 'Manage PAGIBIG',
              route: routes.pagibig,
              base: 'pagibig',
            },
            { // philhealth
              menuValue: 'Manage PhilHealth',
              route: routes.philhealth,
              base: 'philhealth',
            },
            { // Manage Deduction
              menuValue: 'Manage Deductions',
              route: routes.manageDeduction,
              base: 'manage-deduction',
            },
            { // contirbution report
              menuValue: 'Contribution Report',
              route: routes.contributionReport,
              base: 'contribution-report',
            },
          ],
        },
      ],
    },
    { // Admin
      tittle: 'Admin',
      icon: 'file',
      showAsTab: false,
      separateRoute: false,
      menu: [
        { // Assets
          menuValue: 'Assets',
          route: routes.assets,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'object-ungroup',
          base: 'assets',
          materialicons: 'photo_auto_merge',
          subMenus: [
            { // My Purchase Requests
              menuValue: 'My Purchase Requests',
              route: `${routes.prListBy}/${Helper.UserLoginData().ID}`,
              base: 'pr-by',
            },
            { // Manage Item Codes
              menuValue: 'Manage Item Codes',
              route: routes.manageItemCodes,
              base: 'manage-item-codes',
            },
            { // Manage Item Categories
              menuValue: 'Manage Item Categories',
              route: routes.manageItemCategories,
              base: 'manage-item-categories',
            },
            { // Manage PR
              menuValue: 'Manage Purchase Requests',
              route: routes.managePrList,
              base: 'manage-pr',
            },
            { // Manage Suppliers
              menuValue: 'Manage Suppliers',
              route: routes.assetSupplier,
              base: 'supplier',
            },
            { // Manage Assets
              menuValue: 'Manage Assets',
              route: routes.assets,
              base: 'assets',
            },
          ]
        },
        { // Administration
          menuValue: 'Administration',
          route: routes.admin,
          hasSubRoute: true,
          showSubRoute: false,
          icon: 'user-tie',
          base: 'administration',
          materialicons: 'work_outline',
          subMenus: [
            { // vacancy source
              menuValue: 'Manage Vacancy Source',
              route: routes.manageVacancySource,
              base: 'manage-vacancy-source'
            },
            { // departments
              menuValue: 'Manage Departments',
              route: routes.departments,
              base: 'departments',
            },
            { // designations
              menuValue: 'Manage Designations',
              route: routes.designations,
              base: 'designations',
            },
            { // holidays
              menuValue: 'Manage Holidays',
              route: routes.holidays,
              base: 'holidays'
            },
            { // Manage Infraction Templates
              menuValue: 'Manage Infraction Templates',
              route: `${routes.manageInfractionTemplates}/${Helper.UserLoginData().ID}`,
              base: 'manage-infraction-templates'
            },
            { // Manage Violation Codes
              menuValue: 'Manage Violation Codes',
              route: `${routes.manageViolationCodes}`,
              base: 'case-management'
            },
            { // leave-settings
              menuValue: 'Leave Settings',
              route: routes.leavesettings,
              base: 'leave-settings',
            },
            { // AuditLogs
              menuValue: 'Audit Logs',
              route: routes.audit_logs,
              base: 'audit-logs',
            },
          ],
        },
      ],
    },
    { // Organization
      tittle: 'Organization',
      icon: 'building',
      showAsTab: true,
      separateRoute: true,
      menu: [
        { // Organization Chart
          menuValue: 'Organization Chart',
          route: routes.orgChart,
          hasSubRoute: false,
          showSubRoute: false,
          icon: 'building',
          base: 'organization-chart',
          materialicons: 'building',
          subMenus: [],
        },
      ]
    },
  ];

  public getSideBarData: BehaviorSubject<Array<SideBar>> = new BehaviorSubject<Array<SideBar>>(this.sideBar);

  public getSideBar(): BehaviorSubject<Array<SideBar>> {
    let nSidebar: SideBar[] = [];

    if (Helper.can(Helper.accessList["Main"])) nSidebar = this.pushMainTab(nSidebar, ["Main"]);
    if (Helper.can(Helper.accessList["Main>Dashboard"])) nSidebar = this.pushMenus(nSidebar, ["Dashboard"], "Main");
    if (Helper.can(Helper.accessList["Main>Dashboard>Admin"])) nSidebar = this.pushSubMenus(nSidebar, ["admin"], "Main", "Dashboard");
    if (Helper.can(Helper.accessList["Main>Dashboard>Employee"])) nSidebar = this.pushSubMenus(nSidebar, ["employee"], "Main", "Dashboard");
    if (Helper.can(Helper.accessList["Main>Dashboard>Applicant"])) nSidebar = this.pushSubMenus(nSidebar, ["applicant"], "Main", "Dashboard");
    if (Helper.can(Helper.accessList["Employees"])) nSidebar = this.pushMainTab(nSidebar, ["Employees"]);
    if (Helper.can(Helper.accessList["Employees>Employees"])) nSidebar = this.pushMenus(nSidebar, ["Employees"], "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>My Profile"])) nSidebar = this.pushSubMenus(nSidebar, ["my-profile"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>My Leaves"])) nSidebar = this.pushSubMenus(nSidebar, ["leave-employee"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>All Employee"])) nSidebar = this.pushSubMenus(nSidebar, ["employee-page"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>All Applicant"])) nSidebar = this.pushSubMenus(nSidebar, ["all_applicant"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>Manage Leaves"])) nSidebar = this.pushSubMenus(nSidebar, ["leave-admin"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>Manage Trainings"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-training"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Employees>Manage Attendance"])) nSidebar = this.pushSubMenus(nSidebar, ["attendance-admin"], "Employees", "Employees");
    if (Helper.can(Helper.accessList["Employees>Downloadable"])) nSidebar = this.pushMenus(nSidebar, ["Downloadable"], "Employees");
    if (Helper.can(Helper.accessList["HR"])) nSidebar = this.pushMainTab(nSidebar, ["HR"]);
    if (Helper.can(Helper.accessList["HR>Clients"])) nSidebar = this.pushMenus(nSidebar, ["Clients"], "HR");
    if (Helper.can(Helper.accessList["HR>Clients>Manage Clients"])) nSidebar = this.pushSubMenus(nSidebar, ["all-clients"], "HR", "Clients");
    if (Helper.can(Helper.accessList["HR>Clients>Manage Detachments"])) nSidebar = this.pushSubMenus(nSidebar, ["detachments"], "HR", "Clients");
    if (Helper.can(Helper.accessList["HR>Payroll"])) nSidebar = this.pushMenus(nSidebar, ["Payroll"], "HR");
    if (Helper.can(Helper.accessList["HR>Payroll>My Payslip"])) nSidebar = this.pushSubMenus(nSidebar, ["salary-view"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Manage Employee Salary"])) nSidebar = this.pushSubMenus(nSidebar, ["employee-salary"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Manage SSS"])) nSidebar = this.pushSubMenus(nSidebar, ["sss"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Manage PAGIBIG"])) nSidebar = this.pushSubMenus(nSidebar, ["pagibig"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Manage PhilHealth"])) nSidebar = this.pushSubMenus(nSidebar, ["philhealth"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Manage Deductions"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-deduction"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["HR>Payroll>Contribution Report"])) nSidebar = this.pushSubMenus(nSidebar, ["contribution-report"], "HR", "Payroll");
    if (Helper.can(Helper.accessList["Admin"])) nSidebar = this.pushMainTab(nSidebar, ["Admin"]);
    if (Helper.can(Helper.accessList["Admin>Assets"])) nSidebar = this.pushMenus(nSidebar, ["Assets"], "Admin");
    if (Helper.can(Helper.accessList["Admin>Assets>My Purchase Request"])) nSidebar = this.pushSubMenus(nSidebar, ["pr-by"], "Admin", "Assets");
    if (Helper.can(Helper.accessList["Admin>Assets>Manage Item Codes"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-item-codes"], "Admin", "Assets");
    if (Helper.can(Helper.accessList['Admin>Assets>Manage Item Categories'])) nSidebar = this.pushSubMenus(nSidebar, ["manage-item-categories"], "Admin", "Assets");
    if (Helper.can(Helper.accessList["Admin>Assets>Manage Purchase Requests"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-pr"], "Admin", "Assets");
    if (Helper.can(Helper.accessList["Admin>Assets>Manage Suppliers"])) nSidebar = this.pushSubMenus(nSidebar, ["supplier"], "Admin", "Assets");
    if (Helper.can(Helper.accessList["Admin>Assets>Manage Assets"])) nSidebar = this.pushSubMenus(nSidebar, ["assets"], "Admin", "Assets");
    if (Helper.can(Helper.accessList["Admin>Administration"])) nSidebar = this.pushMenus(nSidebar, ["Administration"], "Admin");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Vacancy Source"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-vacancy-source"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Departments"])) nSidebar = this.pushSubMenus(nSidebar, ["departments"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Designations"])) nSidebar = this.pushSubMenus(nSidebar, ["designations"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Holidays"])) nSidebar = this.pushSubMenus(nSidebar, ["holidays"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Infraction Templates"])) nSidebar = this.pushSubMenus(nSidebar, ["manage-infraction-templates"], "Admin", "Administration");
    if (Helper.can(Helper.accessList['Admin>Administration>Manage Violation Codes'])) nSidebar = this.pushSubMenus(nSidebar, ["case-management"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Manage Leave Settings"])) nSidebar = this.pushSubMenus(nSidebar, ["leave-settings"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Admin>Administration>Audit Logs"])) nSidebar = this.pushSubMenus(nSidebar, ["audit-logs"], "Admin", "Administration");
    if (Helper.can(Helper.accessList["Organization"])) nSidebar = this.pushMainTab(nSidebar, ["Organization"]);
    if (Helper.can(Helper.accessList["Organization>Organization Chart"])) nSidebar = this.pushMenus(nSidebar, ["Organization Chart"], "Organization");

    return new BehaviorSubject<Array<SideBar>>(nSidebar);
  }

  private pushMainTab(nSideBar: SideBar[], tabs: string[]): SideBar[] {
    let founds = this.sideBar.filter(sb => tabs.includes(sb.tittle));
    founds = founds.map(sb => ({ ...sb, menu: [] }));
    founds.forEach(v => nSideBar.push(v));
    return nSideBar;
  }

  private pushMenus(nSideBar: SideBar[], menus: string[], mainTab: string): SideBar[] {
    let found = this.sideBar.find(mt => mt.tittle == mainTab);
    let foundMenus = found?.menu.filter(m => menus.includes(m.menuValue));
    foundMenus = foundMenus?.map(mn => ({ ...mn, subMenus: [] }));

    let currMainTab = nSideBar.find(mt => mt.tittle == mainTab);
    foundMenus?.forEach(m => currMainTab?.menu.push(m));
    return nSideBar;
  }

  private pushSubMenus(nSideBar: SideBar[], subMenus: string[], mainTab: string, menu: string) {
    let f_mainTab = this.sideBar.find(mt => mt.tittle == mainTab);
    let f_menu = f_mainTab?.menu.find(m => m.menuValue == menu);
    let f_subMenus = f_menu?.subMenus.filter(sb => subMenus.includes(<string>sb.base));

    let c_mainTab = nSideBar.find(mt => mt.tittle == mainTab);
    let c_menu = c_mainTab?.menu.find(m => m.menuValue == menu);
    f_subMenus?.forEach(sb => c_menu?.subMenus.push(<SubMenu>{ ...sb, menuValue: sb.menuValue }));
    return nSideBar;
  }

}
